import { useMemo, useState } from 'react'
import { Box, Flex, Text, Button, Card } from '@chakra-ui/react'

import {
  MonitoringByActivityZoneDataView,
  useGetAllMonitoringTemplates,
} from 'entities/prices'
import {
  CardBox,
  DatePickerField,
  FormInputBlock,
  SelectInputForm,
} from 'shared/ui'
import { convertDateForAPI } from 'shared/utils'

import { useGetMonitoringByZoneActivity } from './MonitoringByZoneActivityForm'
import { useGetMonitoringByZoneActivityService } from '../models/services/monitoringByZoneActivity'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

type GeneratedData = {
  month: string
  gs_activity_areas_id: string
}

export const MonitoringByZoneActivity = (): JSX.Element => {
  const [fetchEnabled, setFetchEnabled] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const { data: templates } = useGetAllMonitoringTemplates({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useGetMonitoringByZoneActivity()

  const resetFetchState = () => {
    setFetchEnabled({
      enabled: false,
    })
  }

  const convertedDatePeriod = useMemo(
    () => convertDateForAPI(watchedFields.date_period),
    [watchedFields.date_period],
  )

  const selectedTemplate = useMemo(() => {
    return templates?.find(
      (template) =>
        template.monitoring_template_name === watchedFields.template,
    )
  }, [templates, watchedFields.template])

  const generatedData = useMemo<GeneratedData[]>(() => {
    if (!selectedTemplate || !convertedDatePeriod) return []
    return selectedTemplate.gs_activity_areas_ids.map((id) => ({
      month: convertedDatePeriod,
      gs_activity_areas_id: id,
    }))
  }, [selectedTemplate, convertedDatePeriod])

  const { data, isLoading, isFetching, refetch } =
    useGetMonitoringByZoneActivityService({
      enabled: fetchEnabled.enabled,
      data: generatedData,
      date_period: convertedDatePeriod,
      template: watchedFields.template,
      successAction: () => resetFetchState(),
      errorAction: () => resetFetchState(),
    })

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const templatesData = useMemo(() => {
    return templates?.map((template) => ({
      value: template.monitoring_template_name,
      label: template.monitoring_template_name,
    }))
  }, [templates])

  const handleFetchData = () => refetch()

  const MonitoringByActivityZoneDataViewProps = {
    data,
  }

  return (
    <Box p="1">
      <form>
        <Box>
          <CardBox p="10px">
            <Card bg="#fdfdfd" p="10px" boxShadow={'none'} variant={'outline'}>
              <Flex flexDirection={'column'}>
                <Box w={'480px'}>
                  <FormInputBlock
                    titleWidth={'180px'}
                    allowEdit={true}
                    edit
                    title={'Шаблон перечня зон'}
                  >
                    <SelectInputForm
                      placeholder="Выберите шаблон перечня зон"
                      getOptionValue={(option) => (option ? option.value : '')}
                      name="template"
                      data={templatesData || []}
                      isClearable={true}
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Box>

                <Box w={'480px'} mt={'3px'}>
                  <FormInputBlock
                    titleWidth={'180px'}
                    allowEdit={true}
                    title={'Период'}
                    edit={true}
                  >
                    <DatePickerField
                      setInitialDate
                      format="MM.yyyy"
                      showMonthYearPicker
                      name={'date_period'}
                      placeholder={'Период'}
                      type={'text'}
                      theme="monthPicker"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Box>
              </Flex>
            </Card>
          </CardBox>

          <Box mt="15px">
            <Flex>
              <Box>
                <Button
                  w={'135px'}
                  position={'relative'}
                  size={'xs'}
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleFetchData}
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={isLoading || isFetching}
                >
                  <Box fontSize={'18px'} mr={'5px'}>
                    <HiOutlineTableCells />
                  </Box>
                  <Text position={'relative'}>Показать</Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
        {data && (
          <Box
            mt="15px"
            p="2"
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
          >
            <MonitoringByActivityZoneDataView
              {...(MonitoringByActivityZoneDataViewProps as any)}
            />
          </Box>
        )}
      </form>
    </Box>
  )
}
