import { ReactNode, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
  Flex,
  Text,
  FormControl,
  Button,
  FormLabel,
  Box,
  Center,
  Spinner,
  Card,
} from '@chakra-ui/react'

import { useGetUsers } from 'entities/Users'
import { useGetInheritance } from 'entities/editInheritance'
import {
  CardBox,
  FormInputBlock,
  Loader,
  RoleTag,
  SelectInput,
  TextTitle,
} from 'shared/ui'
import { USERS_ROUTE, roleColorScheme } from 'shared/constants'
import { normalizeValues } from 'shared/utils'

import {
  EditUserInheritanceFormData,
  useEditUserInheritanceForm,
} from './UseEditUserInheritanceForm'
import { useUpdateUserInheritance } from '../model/services/useUpdateUserInheritance'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniPencilSquare } from 'react-icons/hi2'

interface EditUserInheritanceProps {
  userId: string
  isCurrentUserAbleToEditAccess: boolean
}

export const EditUserInheritance: React.FC<EditUserInheritanceProps> = (
  props,
) => {
  const { userId, isCurrentUserAbleToEditAccess } = props

  const { usersSelectData, data: usersData } = useGetUsers({
    enabled: false,
  })

  const { usersInheritance, isLoading: isLoadingInheritance } =
    useGetInheritance({
      enabled: !!userId,
      userId,
    })

  const { mutate: updateUserInheritance, isLoading: isLoadingUpdate } =
    useUpdateUserInheritance()

  useEffect(() => {
    if (usersInheritance && usersData) {
      const updatedInheritance = usersInheritance.map((inheritance) => {
        const user = usersData.find(
          (user) => user.user_id === inheritance.from_user,
        )

        return {
          value: inheritance.from_user,
          label: user
            ? `${user.last_name} ${user.first_name}`
            : inheritance.from_user,
          inheritance_id: inheritance.inheritance_id,
          role: user?.role_name,
        }
      })
      reset({
        users: updatedInheritance,
      })
    }
  }, [usersInheritance, usersData])

  const { handleSubmit, errors, reset, control, isDirty } =
    useEditUserInheritanceForm()

  const handleResetForm = () => reset()

  const onSubmit = ({ users }: EditUserInheritanceFormData) => {
    updateUserInheritance({
      userId,
      fromUsers: users.length > 0 ? users.map((user) => user.value) : [],
    })
  }

  const MyCustomComponent: React.FC<{
    children: ReactNode
    data: any
  }> = (props) => {
    const { children, data } = props

    const [isHovered, setIsHovered] = useState<boolean>(false)

    let hoverTimeout: ReturnType<typeof setTimeout>

    const handleMouseEnter = () => {
      hoverTimeout = setTimeout(() => {
        setIsHovered(true)
      }, 300)
    }

    const handleMouseLeave = () => {
      clearTimeout(hoverTimeout)
      setIsHovered(false)
    }

    const handleOpenUserProfile = () => {
      if (data?.value) window.open(`${USERS_ROUTE}/${data.value}`, '_blank')
    }

    useEffect(() => {
      return () => {
        clearTimeout(hoverTimeout)
      }
    }, [])

    return (
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Box
          display={'flex'}
          alignItems={'center'}
          position={'relative'}
          w={'110%'}
          h="110%"
          top="0"
          right="0"
        >
          {isHovered && (
            <Box position={'absolute'} top={'-100px'} left={'-7px'}>
              <Box
                position={'relative'}
                border={'1px solid #dfe0eb'}
                p={'10px'}
                bg="white"
                w="content-fit"
                top={'-60px'}
                shadow={'md'}
                borderRadius={'8px'}
              >
                <Flex m={'15px 5px'} alignItems={'center'}>
                  <Text
                    color="gray.400"
                    mr={'5px'}
                    w={'80px'}
                    fontSize={'16px'}
                  >
                    фио
                  </Text>
                  <Text fontSize={'16px'}>{data?.label}</Text>
                </Flex>
                <Flex m={'15px 5px'} alignItems={'center'}>
                  <Text
                    color="gray.400"
                    mr={'5px'}
                    w={'80px'}
                    fontSize={'16px'}
                  >
                    роль
                  </Text>
                  <RoleTag role={data?.role} size="medium" padding={'3px 5px'}>
                    {normalizeValues(data?.role, 'roles')}
                  </RoleTag>
                </Flex>
                <Flex m={'15px 5px'} alignItems={'center'}>
                  <Text
                    color="gray.400"
                    mr={'5px'}
                    w={'80px'}
                    fontSize={'16px'}
                  >
                    профиль
                  </Text>
                  <Button
                    isLoading={false}
                    isDisabled={false}
                    colorScheme="green"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleOpenUserProfile}
                    size="xs"
                    fontSize={'16px'}
                  >
                    открыть
                    <Box fontSize={'16px'} ml={'10px'}>
                      <HiMiniPencilSquare />
                    </Box>
                  </Button>
                </Flex>
              </Box>
            </Box>
          )}

          <Box
            w={'12px'}
            h={'12px'}
            borderRadius={'50%'}
            background={roleColorScheme[data?.role]}
            mr="6px"
          />
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBox w="1000px">
          <Box p="10px">
            <TextTitle size="small">Наследование</TextTitle>
          </Box>
          <hr style={{ margin: 0 }} />

          {isLoadingInheritance ? (
            <Flex justifyContent="center" minHeight="100px">
              <Loader size="md" />
            </Flex>
          ) : (
            <Flex alignItems={'center'} p="10px">
              <Card
                bg="#fdfdfd"
                p="10px"
                w="100%"
                variant="outline"
                boxShadow={'none'}
              >
                <FormControl
                  id="role"
                  isInvalid={!!errors.users}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <FormLabel
                    color="#718096"
                    w={'150px'}
                    fontSize={'14px'}
                    fontWeight={'500'}
                    m={0}
                  >
                    Пользователи
                  </FormLabel>
                  <Flex flexDirection={'column'} w="100%">
                    <Controller
                      name="users"
                      control={control}
                      render={({ field }) => {
                        return isCurrentUserAbleToEditAccess ? (
                          <Flex flexDirection={'column'} w="100%">
                            <SelectInput
                              size={'sm'}
                              isReadOnly={!isCurrentUserAbleToEditAccess}
                              data={usersSelectData || []}
                              placeholder={'Выберите пользователей'}
                              isClearable
                              isMulti
                              value={field.value}
                              onChange={(selectedOptions) => {
                                field.onChange(selectedOptions)
                              }}
                              error={!!errors.users}
                              customMultiValueLabelComponent={MyCustomComponent}
                            />
                            <Text color="red.600">{errors.users?.message}</Text>
                          </Flex>
                        ) : (
                          <Flex flexDirection={'column'} w="100%">
                            {field.value && field.value.length > 0 ? (
                              field.value.map((selectedOption) => (
                                <Text
                                  key={selectedOption.value}
                                  fontSize={'14px'}
                                  fontWeight={'600'}
                                  color={'#000000a3'}
                                >
                                  {selectedOption.label}
                                </Text>
                              ))
                            ) : (
                              <Text
                                position={'relative'}
                                // top={'-5px'}
                                fontSize={'14px'}
                                fontWeight={'600'}
                                color={'#000000a3'}
                              >
                                Отсутствуют
                              </Text>
                            )}
                          </Flex>
                        )
                      }}
                    />

                    <Text color="red.600">{errors.users?.message}</Text>
                  </Flex>
                </FormControl>
                {isDirty && (
                  <Flex justifyContent={'flex-start'} mt="15px">
                    <Button
                      size={'xs'}
                      type="submit"
                      isLoading={isLoadingUpdate}
                      isDisabled={isLoadingUpdate}
                      bg="teal.400"
                      color="white"
                      display={'flex'}
                      alignItems={'center'}
                      _hover={{
                        bg: 'teal.500',
                      }}
                    >
                      Сохранить
                      <Box fontSize={'22px'} ml={'5px'}>
                        <MdOutlineSave />
                      </Box>
                    </Button>
                    <Button ml="10px" size={'xs'} onClick={handleResetForm}>
                      отмена
                    </Button>
                  </Flex>
                )}
              </Card>
            </Flex>
          )}
        </CardBox>
      </form>
    </Box>
  )
}
