/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import ReactSelect, {
  components as defaultComponents,
  ActionMeta,
  SingleValue,
} from 'react-select'

import CreatableSelect from 'react-select/creatable'

import { ISelect } from './types'
import { getThemes } from './theme'

//  misc
import { ArrowDropDownIcon } from './ArrowDropDownIcon'
import { HiCheckCircle } from 'react-icons/hi2'
import { FaChevronUp } from 'react-icons/fa6'
import { Box } from '@chakra-ui/react'

export const SelectInput: React.FC<ISelect> = ({
  onChange,
  theme = 'filters',
  isReadOnly = false,
  isMulti = false,
  isCreatable = false,
  isDisabled,
  placeholder = 'Поиск',
  defaultValue,
  title,
  data,
  error = false,
  success = false,
  isClearable = false,
  size = 'md',
  notificationText,
  value,
  customMultiValueLabelComponent,
  ...rest
}) => {
  const [key, setKey] = useState<number>(0)
  const [showNotification, setShowNotification] = useState<boolean>(false)

  useEffect(() => {
    if (value === undefined || value === null) setKey((prevKey) => prevKey + 1)
  }, [value])

  const MultiValueRemove = (props) => {
    if (props.data.isFixed) return null
    return <defaultComponents.MultiValueRemove {...props} />
  }

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option' && notificationText)
      setShowNotification(true)
    else setShowNotification(false)

    if (actionMeta.action === 'clear') {
      if (isMulti) {
        onChange(value.filter((option) => option.isFixed))
      } else {
        onChange(null)
      }
    } else if (
      isMulti &&
      (actionMeta.action === 'remove-value' ||
        actionMeta.action === 'pop-value') &&
      actionMeta.removedValue.isFixed
    ) {
    } else {
      onChange(newValue)
    }
  }

  const DropdownIndicator = (props) =>
    defaultComponents.DropdownIndicator && (
      <defaultComponents.DropdownIndicator {...props}>
        <div
          style={{
            opacity:
              !value || isClearable === false || value.length === 0 ? 1 : 0,
          }}
        >
          <ArrowDropDownIcon />
        </div>
      </defaultComponents.DropdownIndicator>
    )

  const CustomMultiValueLabel = customMultiValueLabelComponent
    ? (props) => {
        const CustomComponent = customMultiValueLabelComponent

        // return <CustomComponent {...props} />

        return (
          <defaultComponents.MultiValueLabel {...props}>
            <CustomComponent {...props}>{props.children}</CustomComponent>
          </defaultComponents.MultiValueLabel>
        )
      }
    : defaultComponents.MultiValueLabel

  const components = {
    ...defaultComponents,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueLabel: CustomMultiValueLabel,
    MultiValueRemove,
  }

  const SelectComponent = isCreatable ? CreatableSelect : ReactSelect

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
      key={key}
    >
      {title}
      {showNotification && notificationText && (
        <div
          style={{
            position: 'absolute',
            fontSize: '10px',
            color: '#399b75',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 700,
            background: '#fff',
            top: '-7px',
            zIndex: 9,
            left: '6px',
            padding: '0 3px',
          }}
        >
          <HiCheckCircle style={{ fontSize: '10px', marginRight: '1px' }} />{' '}
          {notificationText}
        </div>
      )}
      <SelectComponent
        {...rest}
        isClearable={isClearable}
        isMulti={isMulti}
        styles={getThemes(error, success)[size]}
        isDisabled={isReadOnly}
        options={data}
        defaultValue={defaultValue}
        components={components}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        menuPortalTarget={document?.querySelector('body')}
        noOptionsMessage={() => 'Совпадений не обнаружено'}
        formatCreateLabel={(inputValue) => (
          <span>
            Создать <b>{`${inputValue}`}</b>
          </span>
        )}
      />
    </div>
  )
}
