import React, { useCallback, useEffect, useState } from 'react'

import {
  Control,
  FieldErrors,
  FormState,
  UseFormRegister,
} from 'react-hook-form'

import {
  Flex,
  Text,
  Button,
  Box,
  IconButton,
  Divider,
  Card,
} from '@chakra-ui/react'

import {
  CardBox,
  FormInputBlock,
  FormInputControl,
  Loader,
  RoleTag,
  SelectInputForm,
  TextTitle,
} from 'shared/ui'

import { normalizeValues } from 'shared/utils'
import { useGetUserInfo } from 'entities/User'

import {
  EditUserPersonalProfileFormData,
  useEditUserPersonalProfileForm,
} from './UseEditUserPersonalProfileForm'

import { useUpdateUserPersonalProfile } from '../models/services/useUpdateUserPersonalProfile'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'
import { useGetEmployeesPositions } from 'entities/employeesPositions'
import { useAccess } from 'app/providers'
import { ROLES } from 'shared/constants'

interface EditProps {
  handleResetForm: () => void
  errors: FieldErrors<EditUserPersonalProfileFormData>
  control: Control<EditUserPersonalProfileFormData>
  isDirty: boolean
  isLoadingUpdate: boolean
  register: UseFormRegister<EditUserPersonalProfileFormData>
  role: string
  position_name: string
  last_name: string
  first_name: string
  middle_name: string
  touchedFields: FormState<EditUserPersonalProfileFormData>['touchedFields']
  watchedFields: EditUserPersonalProfileFormData
}

interface ViewProps {
  email: string
  role: string
  username: string
  last_name: string
  first_name: string
  position_name: string
  middle_name?: string
}

export const EditUserPersonalProfile: React.FC = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { userInfo, isLoading } = useGetUserInfo({
    enabled: false,
  })

  const { mutate: updateUserProfile, isLoading: isLoadingUpdate } =
    useUpdateUserPersonalProfile()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
  } = useEditUserPersonalProfileForm()

  const { employeesPositionsData } = useGetEmployeesPositions({
    enabled: false,
  })

  const { hasAccess } = useAccess()

  const accessAllowedEdit = hasAccess({ roles: [ROLES.SUPERADMIN] })

  useEffect(() => {
    if (userInfo) {
      reset({
        email: userInfo.email,
        role: userInfo.role_name,
        username: userInfo.username,
        last_name: userInfo.last_name,
        first_name: userInfo.first_name,
        position_name: userInfo.position_name,
        middle_name: userInfo?.middle_name || '',
      })
    }
  }, [userInfo, reset])

  const onSubmit = ({
    email,
    username,
    last_name,
    first_name,
    middle_name,
    position_name,
  }: EditUserPersonalProfileFormData) => {
    updateUserProfile({
      isSuperAdmin: accessAllowedEdit,
      email,
      username,
      last_name,
      first_name,
      middle_name,
      position_name,
      successAction: () => setIsEdit(false),
    })
  }

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  if (isLoading)
    return (
      <Flex justifyContent="center" minHeight="200px">
        <Loader size="md" />
      </Flex>
    )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardBox w={'1000px'} position={'relative'} mt={'15px'}>
        <Box p="10px">
          <TextTitle size="small">Персональные данные</TextTitle>
        </Box>

        <hr style={{ margin: 0 }} />
        <Flex
          w="100%"
          justifyContent="flex-end"
          position={'absolute'}
          right={'15px'}
          top={'5px'}
        >
          <IconButton
            size="sm"
            aria-label="Edit Activity"
            icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
            onClick={handleEditClick}
            fontSize="22px"
            {...(isEdit ? { colorScheme: 'red' } : {})}
          />
        </Flex>

        <Box m={'3px 0'} p={'10px'}>
          <Card bg="#fdfdfd" p="10px" boxShadow={'none'} variant={'outline'}>
            <FormInputBlock
              allowEdit={accessAllowedEdit}
              title={'ФИО'}
              edit={isEdit}
              value={`${userInfo.last_name || ''} ${
                userInfo.first_name || ''
              } ${userInfo?.middle_name || ''}`}
            >
              <FormInputControl
                name="last_name"
                smallErrorTextInside
                size="sm"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired={true}
                placeholder="Фамилия"
                type="text"
              />
              <FormInputControl
                smallErrorTextInside
                size="sm"
                name="first_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired={true}
                placeholder="Имя"
                type="text"
              />
              <FormInputControl
                smallErrorTextInside
                size="sm"
                name="middle_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired={false}
                placeholder="Отчество"
                type="text"
              />
            </FormInputBlock>

            <Box m={'3px 0'} />
            <FormInputBlock
              allowEdit={accessAllowedEdit}
              title={'Должность'}
              edit={isEdit}
              value={userInfo.position_name}
            >
              <SelectInputForm
                isRequired
                watchedFields={watchedFields}
                errors={errors}
                size={'sm'}
                register={register}
                smallErrorTextInside
                control={control}
                name="position_name"
                data={employeesPositionsData}
                isCreatable={true}
                isClearable={true}
                notificationText="Новая должность будет создана"
                getOptionValue={(option) => (option ? option.value : '')}
              />
            </FormInputBlock>
            <Box m={'3px 0'} />
            <Flex alignItems={'center'} h={'32px'} display={'flex'}>
              <Text
                color="#718096"
                w={'120px'}
                fontSize={'14px'}
                fontWeight={'500'}
              >
                Роль:
              </Text>
              <Box ml={'10px'}>
                <RoleTag role={userInfo.role_name} size="small">
                  <>{normalizeValues(userInfo.role_name, 'roles')}</>
                </RoleTag>
              </Box>
            </Flex>
            <Box m={'3px 0'} />
            <FormInputBlock
              allowEdit={true}
              title={'Логин'}
              edit={isEdit}
              value={userInfo.username}
            >
              <FormInputControl
                smallErrorTextInside
                size="sm"
                name="username"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Логин"
                type="text"
              />
            </FormInputBlock>
            <Box m={'3px 0'} />
            <FormInputBlock
              allowEdit={accessAllowedEdit}
              title={'Email'}
              edit={isEdit}
              value={userInfo.email}
            >
              <FormInputControl
                smallErrorTextInside
                size="sm"
                name="email"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Email"
                type="text"
              />
            </FormInputBlock>
            {isDirty && (
              <Flex justifyContent={'flex-start'} mb={'15px'} pt="20px">
                <Button
                  size={'xs'}
                  type="submit"
                  isLoading={isLoadingUpdate}
                  isDisabled={isLoadingUpdate}
                  bg="teal.400"
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  _hover={{
                    bg: 'teal.500',
                  }}
                >
                  Сохранить
                  <Box fontSize={'22px'} ml={'5px'}>
                    <MdOutlineSave />
                  </Box>
                </Button>
                <Button ml="10px" size={'xs'} onClick={handleResetForm}>
                  Отмена
                </Button>
              </Flex>
            )}
          </Card>
        </Box>
      </CardBox>
    </form>
  )
}
