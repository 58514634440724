export const antTheme = {
  components: {
    Button: {
      colorPrimary: '#00b96b',
      algorithm: true, // Enable algorithm
    },
    Input: {
      colorPrimary: '#eb2f96',
      algorithm: true, // Enable algorithm
    },
  },
}
