import { useQuery } from 'react-query'

import { TransServiceResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface TransServiceData {
  value: string
  label: string
}

interface UseGetTransServiceResult {
  data: TransServiceResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  transServiceData: TransServiceData[] | undefined
}

interface UseTransService {
  enabled: boolean
}

export function useGetTransService({
  enabled,
}: UseTransService): UseGetTransServiceResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    TransServiceResponse[]
  >({
    queryKey: [queryKeys.priceCategories.transService.allTransService],
    queryFn: () =>
      PriceCategoriesService.getListTransService().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const transServiceData = data?.map((x) => ({
    value: x.base_trans_service_id,
    label: `${x.base_element_name} `,
  }))

  return { data, isError, isFetching, isLoading, transServiceData }
}
