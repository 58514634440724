import { Box, BoxProps } from '@chakra-ui/react'

interface HeaderWithSkewProps extends BoxProps {
  children: React.ReactNode
  bg?: string
}

export const HeaderWithSkew: React.FC<HeaderWithSkewProps> = ({
  children,
  bg = '#319795',
  // skewSize = '15px',
  ...rest
}): JSX.Element => {
  return (
    <Box
      position="relative"
      p="3px 5px"
      // bg={bg}
      color="white"
      bg={bg}
      // clipPath="polygon(10px 0, 100% 0, calc(100% - 10px) 100%, 0 100%)"
      // clipPath={`polygon(0 0, calc(100% - ${skewSize}) 0, 100% 100%, 0 100%)`}
      // borderRadius="0 20px 20px 0"
      // bgGradient="linear(to-r, teal.500, blue.500)"
      // boxShadow="inset 0 2px 5px rgba(0, 0, 0, 0.5)"
      // clipPath="polygon(0 0, 100% 0, 100% calc(100% - 10px), 10px 100%, 0 calc(100% - 10px))"
      clipPath="polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0 100%)"
      {...rest}
    >
      {/* <Box
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="5px"
        bg="yellow.500"
      /> */}
      {children}
    </Box>
  )
}
