import React, { useCallback, useEffect, useState } from 'react'
import { Text, Button, Flex, IconButton, Box } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { FormInputControl, ListView } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  useEditPriceZoneForm,
  EditPriceZoneFormData,
} from './UseEditPriceZoneForm'

import { DeletePriceZone } from '../../deletePriceZone'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'
import { useUpdatePriceZone } from '../models/services/useUpdateEnegrySystems'

interface PriceZoneItemProps {
  zone_name: string
  price_zone_id: string
  code_id: number
}

export const PriceZoneItem: React.FC<PriceZoneItemProps> = (props) => {
  const { code_id, zone_name, price_zone_id } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { mutate: updatePriceZone, isLoading: isUpdatePriceZoneLoading } =
    useUpdatePriceZone()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useEditPriceZoneForm()

  useEffect(() => {
    if (price_zone_id)
      reset({
        zone_name,
      })
  }, [code_id, price_zone_id, zone_name, reset])

  const handleEditClick = useCallback(() => {
    if (isEdit) {
      reset()
      setIsEdit(false)
    } else setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = ({ zone_name }: EditPriceZoneFormData) => {
    updatePriceZone({
      zone_name,
      price_zone_id,
      successAction: () => setIsEdit(false),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListView.Stacks>
        <Flex alignItems={'center'} ml={'5px'}>
          <Text w={'35px'} fontSize={'sm'}>
            {code_id}
          </Text>

          {isEdit ? (
            <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="zone_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя ОЭС"
                type="text"
              />
            </Flex>
          ) : (
            <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'} m="2px 0">
              {zone_name}
            </Text>
          )}
        </Flex>

        <Flex alignItems={'center'}>
          {isEdit && isDirty && (
            <Box>
              <Flex justifyContent={'flex-start'}>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isUpdatePriceZoneLoading}
                  isLoading={isUpdatePriceZoneLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
          {isEdit && (
            <Box mr={'15px'}>
              <DeletePriceZone
                price_zone_id={price_zone_id}
                zone_name={zone_name}
              />
            </Box>
          )}

          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <IconButton
              size="xs"
              aria-label="Edit Activity"
              icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
              onClick={handleEditClick}
              fontSize="20px"
              // {...(isEdit ? { colorScheme: 'red' } : {})}
            />
          </Access>
        </Flex>
      </ListView.Stacks>
    </form>
  )
}
