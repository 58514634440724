import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import {
  CreateMonitoringTemplate,
  EditMonitoringTemplates,
  MonitoringByMoth,
  MonitoringByZoneActivity,
} from 'features/prices'
import { useGetAllMonitoringTemplates } from 'entities/prices'

import { MonitoringType } from '../types/types'

// misc
import { HiArrowLeftCircle } from 'react-icons/hi2'
import { HiCog } from 'react-icons/hi2'

export const Monitoring = (): JSX.Element => {
  const [monitoringType, setMonitoringType] = useState<MonitoringType>(
    MonitoringType.byDeliveryZone,
  )
  const [openedTemplates, setOpenedTemplates] = useState<boolean>(false)

  const { data: templates } = useGetAllMonitoringTemplates({
    enabled: true,
  })

  const handleOpenTemplates = () => setOpenedTemplates(true)

  return (
    <Box p="2">
      <Box>
        <Flex>
          <Button
            size="xs"
            borderRadius={0}
            borderLeftRadius={'5px'}
            border={'1px solid #ececec'}
            borderRight={'none'}
            _hover={{ bg: '#3285d2', color: 'white' }}
            w={'200px'}
            bg={
              monitoringType === MonitoringType.byDeliveryZone
                ? '#2d70af'
                : '#f8f8f8'
            }
            color={
              monitoringType === MonitoringType.byDeliveryZone
                ? 'white'
                : 'black'
            }
            onClick={() => setMonitoringType(MonitoringType.byDeliveryZone)}
          >
            По зонам деятельности ГП
          </Button>
          <Button
            w={'200px'}
            size="xs"
            borderRadius={0}
            borderRightRadius={'5px'}
            border={'1px solid #ececec'}
            _hover={{ bg: '#3285d2', color: 'white' }}
            bg={
              monitoringType === MonitoringType.byMonth ? '#2d70af' : '#f8f8f8'
            }
            color={
              monitoringType === MonitoringType.byMonth ? 'white' : 'black'
            }
            onClick={() => setMonitoringType(MonitoringType.byMonth)}
          >
            По месяцам
          </Button>
        </Flex>
      </Box>
      {monitoringType === MonitoringType.byDeliveryZone && (
        <Box position={'relative'}>
          <Box mb="10px" mt="10px">
            <Button
              mr="10px"
              position={'relative'}
              size={'xs'}
              bg={openedTemplates ? 'white' : 'teal.400'}
              color="white"
              display={'flex'}
              alignItems={'center'}
              onClick={
                openedTemplates
                  ? () => setOpenedTemplates(false)
                  : () => handleOpenTemplates()
              }
              border={openedTemplates && '1px solid #bababa'}
              _hover={
                openedTemplates
                  ? {
                      bg: 'gray.100',
                    }
                  : {
                      bg: 'teal.500',
                    }
              }
            >
              <Box
                fontSize={'18px'}
                mr={'5px'}
                color={openedTemplates && '#28aa72'}
              >
                {openedTemplates ? <HiArrowLeftCircle /> : <HiCog />}
              </Box>
              <Text position={'relative'} color={openedTemplates && 'black'}>
                {openedTemplates ? 'Назад к мониторингу' : 'Шаблоны'}
              </Text>
            </Button>
          </Box>
        </Box>
      )}

      <Tabs
        index={monitoringType === MonitoringType.byDeliveryZone ? 0 : 1}
        isManual
      >
        <TabPanels>
          <TabPanel padding={0}>
            <Box>
              <Tabs index={openedTemplates ? 0 : 1} isManual>
                <TabPanels>
                  <TabPanel padding={0}>
                    <Box
                      bg={'white'}
                      borderRadius={'5px'}
                      w={'100%'}
                      h={'100%'}
                      border={'1px solid #dfe0eb'}
                    >
                      <CreateMonitoringTemplate
                        setOpenedTemplates={setOpenedTemplates}
                      />
                      <EditMonitoringTemplates
                        openedTemplates={openedTemplates}
                        setOpenedTemplates={setOpenedTemplates}
                        templates={templates}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel padding={0}>
                    <MonitoringByZoneActivity />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </TabPanel>
          <TabPanel padding={0}>
            <Box>
              <MonitoringByMoth />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
