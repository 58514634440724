import React, { useMemo, useState, memo, useEffect } from 'react'
import { Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  DeletePeakHoursIntervalsReport,
  DownloadPeakHoursIntervalsReport,
} from 'features/prices'
import { useUseGetDayZoneIntervalsService } from 'entities/prices'
import { useGetAllEnergySystems } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { normalizeValues } from 'shared/utils'

import { EditDayZoneIntervals } from 'features/prices'
import { useDayZoneIntervalsForm } from './DayZoneIntervalsForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

interface DayZoneIntervalsProps {
  query_date?: string
  query_ue_system_id?: string
}

export const DayZoneIntervals: React.FC<DayZoneIntervalsProps> = memo(
  ({ query_date, query_ue_system_id }): JSX.Element => {
    const [fetchEnabled, setFetchEnabled] = useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
    } = useDayZoneIntervalsForm()

    const year = normalizeValues(watchedFields.date_period, 'date_only_year')

    const { data, isLoading, isFetching, refetch } =
      useUseGetDayZoneIntervalsService({
        enabled: fetchEnabled.enabled,
        ue_system_id: watchedFields.ue_system_id,
        date_period: year,
      })

    const { energySystemsData } = useGetAllEnergySystems({
      enabled: true,
    })

    useEffect(() => {
      if (query_date || query_ue_system_id) {
        reset({
          date_period: query_date
            ? normalizeValues(query_date, 'date_only_year')
            : null,
          ue_system_id: query_ue_system_id ? query_ue_system_id : null,
        })
      }
    }, [query_date, query_ue_system_id])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const editDayZoneIntervalsProps = {
      data,
      year,
      ue_system_id: watchedFields.ue_system_id,
    }

    const downloadDayZoneIntervalsReportProps = {
      year,
      ue_system_id: watchedFields.ue_system_id,
      report_type: 'sales_allowances_gs',
      isDisabled: watchedFields.ue_system_id && !watchedFields.date_period,
      reportName: 'интервалы тарифных зон суток',
    }

    const deleteDayZoneIntervalsReportProps = {
      report_type: 'day_zone_intervals',
      year,
      ue_system_id: watchedFields.ue_system_id,
    }

    const handleFetchData = () => refetch()

    return (
      <Box p="2">
        <form>
          <Box ml="10px">
            <Flex alignItems={'center'}>
              <Box mr="25px" w={'150px'}>
                <FormInputBlock
                  titleWidth={'60px'}
                  allowEdit={true}
                  title={'Период'}
                  edit={true}
                >
                  <DatePickerField
                    setInitialDate
                    showOnlyYearPicker
                    showYearPicker
                    format="yyyy"
                    showMonthYearPicker
                    name={'date_period'}
                    placeholder={'Период'}
                    type={'text'}
                    theme="yearPicker"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w={'600px'}>
                <FormInputBlock
                  titleWidth={'40px'}
                  allowEdit={true}
                  edit
                  title={'ОЭС'}
                >
                  <SelectInputForm
                    placeholder="Выберите ОЭС"
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="ue_system_id"
                    data={energySystemsData || []}
                    isClearable={true}
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <Box mt="20px">
              <Flex>
                <Box ml="10px">
                  <Button
                    w={'135px'}
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleFetchData}
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isLoading || isFetching}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiOutlineTableCells />
                    </Box>
                    <Text position={'relative'}>Показать</Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>

          {data && (
            <Box
              mt="15px"
              p="2"
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
            >
              <EditDayZoneIntervals
                downloadReportSlot={
                  <DownloadPeakHoursIntervalsReport
                    {...downloadDayZoneIntervalsReportProps}
                  />
                }
                deleteReportSlot={
                  <DeletePeakHoursIntervalsReport
                    {...deleteDayZoneIntervalsReportProps}
                  />
                }
                {...editDayZoneIntervalsProps}
              />
            </Box>
          )}
        </form>
      </Box>
    )
  },
)
