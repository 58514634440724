export const queryKeys = {
  authentication: {
    refresh: 'authentication/refreshToken',
    login: 'authentication/login',
  },
  user: {
    data: 'user/data',
    logout: 'user/logout',
    addNewUser: 'user/addNewUser',
    info: 'user/info',
    registration: 'user/registration',
    resetPassword: 'user/resetPassword',
    companies: 'user/companies',
  },
  users: {
    allUsers: 'users/allUsers',
    userInfo: 'users/userInfo',
    updateUserInfo: 'users/updateUserInfo',
  },
  dictionaries: {
    employeesPositions: 'dictionaries/employeesPositions',
  },
  modules: {
    userRights: 'modules/userRights',
    userInheritance: 'modules/userInheritance',
  },
  companies: {
    allCompanies: 'companies/allCompanies',
    editableCompanies: 'companies/editableCompanies',
    companyDetail: 'companies/companyDetail',
  },
  holydays: {
    allHolydays: 'holydays/allHolydays',
  },
  referenceBooks: {
    subjects: {
      allSubjects: 'referenceBooks/subjects/allSubjects',
    },
    energySystems: {
      allEnergySystems: 'referenceBooks/energySystems/allEnergySystems',
    },
    priceZones: {
      allPriceZones: 'referenceBooks/priceZones/allPriceZones',
    },
    activityAreas: {
      allActivityAreas: 'referenceBooks/activityAreas/allActivityAreas',
      detailActivityAreas: 'referenceBooks/activityAreas/detailActivityAreas',
      editGroupDelivery: 'referenceBooks/activityAreas/editGroupDelivery',
      allGroupDelivery: 'referenceBooks/activityAreas/allGroupDelivery',
      allJuridicalPersons: 'referenceBooks/activityAreas/allJuridicalPersons',
    },
  },
  priceCategories: {
    priceCategories: {
      allPriceCategories: 'priceCategories/priceCategories/allPriceCategories',
    },
    salesAllowances: {
      allSalesAllowances: 'priceCategories/salesAllowances/allSalesAllowances',
    },
    tariffTransmissionService: {
      allTariffTransmissionService:
        'priceCategories/tariffTransmissionService/allTariffTransmissionService',
    },
    dayZones: {
      allDayZones: 'priceCategories/dayZones/allDayZones',
    },
    products: {
      allProducts: 'priceCategories/products/allProducts',
    },
    svncem: {
      allSvncem: 'priceCategories/svncem/allSvncem',
    },
    salesAllowance: {
      allSalesAllowance: 'priceCategories/salesAllowance/allSalesAllowance',
    },
    transService: {
      allTransService: 'priceCategories/transService/allTransService',
    },
    usersTariffs: {
      allUsersTariffs: 'priceCategories/usersTariffs/allUsersTariffs',
    },
    others: {
      allOthers: 'priceCategories/others/allOthers',
    },
    detailsCalculationPrice: {
      allDetailsCalculationPrice:
        'priceCategories/detailsCalculationPrice/allDetailsCalculationPrice',
    },
    priceATC: {
      allPriceATC: 'priceCategories/PriceATC/allPriceATC',
    },
  },
  counteragentsContracts: {
    calculationSchemes: {
      allCalculationSchemes:
        'counteragentsContracts/calculationSchemes/allCalculationSchemes',
    },
    additionalRequisite: {
      allAdditionalRequisite:
        'counteragentsContracts/additionalRequisite/allAdditionalRequisite',
    },
    typesContracts: {
      allTypesContracts:
        'counteragentsContracts/typesContracts/allTypesContracts',
    },
    typeCounteragents: {
      allTypesCounteragents:
        'counteragentsContracts/typeCounteragents/allTypesCounteragents',
      detailTypesCounteragents:
        'counteragentsContracts/typeCounteragents/detailTypesCounteragents',
    },
  },
  prices: {
    handleUpload: {
      uploadFile: 'prices/handleUpload/uploadFile',
      uploadFileATC: 'prices/handleUpload/uploadFileATC',
    },
    atcUpload: {
      uploadFile: 'prices/handleUpload/atcUpload',
    },
    dataViewSVNCEMByHour: 'prices/dataViewSVNCEMByHour',
    dataViewSVNCEMByMont: 'prices/dataViewSVNCEMByMont',
    dataViewATCHours: 'prices/dataViewATCHours',
    dataViewFSKTariffs: 'prices/dataViewFSKTariffs',
    allATSTemplates: 'prices/allATCTemplates',
    salesMarkups: 'prices/salesMarkups',
    transService: 'prices/transService',
    transServiceFSKNetworks: 'prices/transServiceFSKNetworks',
    transServiceFSKLosses: 'prices/transServiceFSKLosses',
    gpData: 'prices/gpData',
    ueSystemOperatorHours: 'prices/ueSystemOperatorHours',
    dayZoneIntervalsService: 'prices/dayZoneIntervalsService',
    allMonitoringTemplates: 'prices/allMonitoringTemplates',
    monitoringByZoneActivity: 'prices/monitoringByZoneActivity',
    monitoringByMonth: 'prices/monitoringByMonth',
    productsThree: 'prices/productsThree',
  },
}
