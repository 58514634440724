import { useQuery } from 'react-query'

import { OthersResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface OthersServiceData {
  value: string
  label: string
}

interface UseGetOthersResult {
  data: OthersResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  othersServiceData: OthersServiceData[] | undefined
}

interface UseGetOthersService {
  enabled: boolean
}

export function useGetOthers({
  enabled,
}: UseGetOthersService): UseGetOthersResult {
  const { data, isError, isFetching, isLoading } = useQuery<OthersResponse[]>({
    queryKey: [queryKeys.priceCategories.others.allOthers],
    queryFn: () =>
      PriceCategoriesService.getListOthers().then((response) => response.data),
    enabled,
    retry: false,
  })

  const othersServiceData = data?.map((x) => ({
    value: x.tariff_other_id,
    label: `${x.base_element_name} `,
  }))

  return { data, isError, isFetching, isLoading, othersServiceData }
}
