import { useEffect } from 'react'

import {
  Flex,
  Text,
  FormControl,
  Button,
  FormLabel,
  Box,
  Center,
  Spinner,
  Card,
} from '@chakra-ui/react'

import { Controller } from 'react-hook-form'

import { useGetUserCompanies } from 'entities/userCompanies'
import { useGetEditableCompanies } from 'entities/companies'

import { useEditUserCompaniesForm } from './UseEditCompaniesForm'
import { useUpdateUserCompanies } from '../services/useUpdateUserCompanies'

//  misc
import {
  CardBox,
  Loader,
  SelectInput,
  SelectInputMultiForm,
  TextTitle,
} from 'shared/ui'
import { MdOutlineSave } from 'react-icons/md'

interface EditUserCompaniesProps {
  userId: string
  isCurrentUserAbleToEditCompanies: boolean
}

export const EditUserCompanies: React.FC<EditUserCompaniesProps> = (props) => {
  const { userId, isCurrentUserAbleToEditCompanies } = props

  const { mutate: updateUserCompanies, isLoading: isLoadingUpdate } =
    useUpdateUserCompanies()

  const { isLoading, userCompanies } = useGetUserCompanies({
    enabled: !!userId,
    userId,
  })

  const {
    isFetching: isFetchingCompanies,
    data: editableCompanies,
    editableCompaniesData,
  } = useGetEditableCompanies({
    enabled: !!userId,
  })

  const {
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
    register,
  } = useEditUserCompaniesForm()

  useEffect(() => {
    if (userCompanies && editableCompanies) {
      const mergedCompanies = userCompanies.map((company) => ({
        ...company,
        label: company.short_name,
        value: company.company_id,
        isFixed: !editableCompanies.some(
          (ec) => ec.company_id === company.company_id,
        ),
      }))

      const sortedCompanies = mergedCompanies.sort(
        (a, b) => +b.isFixed - +a.isFixed,
      )

      reset({ companies: sortedCompanies })
    }
  }, [userCompanies, editableCompanies, reset])

  const handleResetForm = () => reset()

  const onSubmit = ({ companies }) => {
    updateUserCompanies({
      userId,
      companiesIds:
        companies.length > 0 ? companies.map((company) => company.value) : [],
    })
  }

  return (
    <Box mb="10px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBox w="1000px">
          <Box p="10px">
            <TextTitle size="small">Компании</TextTitle>
          </Box>
          <hr style={{ margin: 0 }} />
          <Box mt="10px" p="10px">
            {isLoading ? (
              isLoading
            ) : (
              <Card
                p="10px"
                bg="#fdfdfd"
                w="100%"
                boxShadow={'none'}
                variant={'outline'}
              >
                <Flex alignItems={'center'}>
                  <FormControl
                    id="role"
                    isInvalid={!!errors.companies}
                    display={'flex'}
                    alignItems={'center'}
                    m="0"
                  >
                    <FormLabel
                      color="#718096"
                      w={'150px'}
                      fontSize={'14px'}
                      fontWeight={'500'}
                      m="0"
                    >
                      Компании
                    </FormLabel>
                    <Flex flexDirection={'column'} w={'100%'}>
                      <SelectInputMultiForm
                        isRequired={false}
                        isCreatable={false}
                        register={register}
                        control={control}
                        name={'companies'}
                        smallErrorTextInside
                        watchedFields={watchedFields}
                        size={'sm'}
                        isReadOnly={!isCurrentUserAbleToEditCompanies}
                        data={editableCompaniesData}
                        placeholder="Выберите компании"
                        // isClearable
                        isMulti
                        errors={errors}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
                {isDirty && (
                  <Flex justifyContent={'flex-start'} mt="25px">
                    <Button
                      size={'xs'}
                      type="submit"
                      isLoading={isLoadingUpdate}
                      isDisabled={isLoadingUpdate}
                      bg="teal.400"
                      color="white"
                      display={'flex'}
                      alignItems={'center'}
                      _hover={{
                        bg: 'teal.500',
                      }}
                    >
                      Сохранить
                      <Box fontSize={'22px'} ml={'5px'}>
                        <MdOutlineSave />
                      </Box>
                    </Button>
                    <Button ml="10px" onClick={handleResetForm} size={'xs'}>
                      Отмена
                    </Button>
                  </Flex>
                )}
              </Card>
            )}
          </Box>
        </CardBox>
      </form>
    </Box>
  )
}
