import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, Flex } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { useGetDetailTypesContract } from 'entities/counteragentsContracts'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  CardBox,
  EditButton,
  FormInputBlock,
  FormInputControl,
  Loader,
  SubmitCancelButtons,
  TextLabelTitle,
  TextTitle,
  TextValueTitle,
} from 'shared/ui'

import {
  EditTypeCounteragentsFormData,
  useEditTypeCounteragentsForm,
} from './UseEditActivityForm'
import { useUpdateCounteragent } from '../models/services/useUpdateActivityArea'
import { DeleteTypeCounteragents } from 'features/counteragentsContracts'
import { RelatedTypeContractList } from '../../editRelatedTypeContract'
import { CreateRelatedTypeContract } from '../../createRelatedTypeContract'

interface ActivityItemProps {
  type_counteragent_id: string
  short_name: string
  full_name: string
  code_id: number
  isExpanded: boolean
  setExpandedIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const TypeCounteragentItem: React.FC<ActivityItemProps> = React.memo(
  (props) => {
    const {
      type_counteragent_id,
      isExpanded,
      setExpandedIds,
      short_name,
      full_name,
      code_id,
    } = props

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const { data: typesContracts, isLoading: isTypeCounteragentLoading } =
      useGetDetailTypesContract({
        enabled: isExpanded && !!type_counteragent_id,
        type_counteragent_id,
      })

    const {
      mutate: updateTypeCounteragent,
      isLoading: isUpdatingTypeCounteragent,
    } = useUpdateCounteragent()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      isDirty,
      watchedFields,
    } = useEditTypeCounteragentsForm()

    useEffect(() => {
      if (full_name && short_name) {
        reset({
          full_name,
          short_name,
        })
      }
    }, [reset, full_name, short_name])

    const onSubmit = ({
      short_name,
      full_name,
    }: EditTypeCounteragentsFormData) => {
      updateTypeCounteragent({
        successAction: () => setIsEdit(false),
        short_name,
        full_name,
        type_counteragent_id,
      })
    }

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    const handleResetForm = useCallback(() => {
      reset()
    }, [reset])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    if (isTypeCounteragentLoading)
      return (
        <Box m="150px 0">
          <Loader size="sm" />
        </Box>
      )

    return (
      <Box position={'relative'}>
        <CardBox w={'1000px'} position={'relative'} p="15px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Access
              permissions={[accessDict.update_read]}
              module={modulesDict.reference_books}
            >
              <Flex
                w="100%"
                justifyContent="flex-end"
                position={'absolute'}
                right={'10px'}
                top={'10px'}
              >
                <EditButton
                  handleEditClick={handleEditClick}
                  isEdit={isEdit}
                  size={'xs'}
                />
              </Flex>
            </Access>

            <Box maxW={'820px'}>
              <Card
                bg="#fdfdfd"
                p="10px"
                boxShadow={'none'}
                variant={'outline'}
              >
                <Flex alignItems={'center'}>
                  <TextLabelTitle w={'212px'}>ID:</TextLabelTitle>
                  <TextValueTitle size={'smallSource'}>
                    {code_id}
                  </TextValueTitle>
                </Flex>
                <Box m={'3px'} />
                <FormInputBlock
                  titleWidth={'200px'}
                  allowEdit={true}
                  title={'Сокращение:'}
                  edit={isEdit}
                  value={short_name || ''}
                >
                  <FormInputControl
                    name="short_name"
                    placeholder="Сокращение"
                    type="text"
                    {...commonInputProps}
                  />
                </FormInputBlock>
                <Box m={'3px'} />
                <FormInputBlock
                  titleWidth={'200px'}
                  allowEdit={true}
                  title={'Наименование:'}
                  edit={isEdit}
                  value={full_name || ''}
                >
                  <FormInputControl
                    name="full_name"
                    placeholder="Наименование"
                    type="text"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Card>
            </Box>
          </form>
          <Box
            width={'fit-content'}
            mt={'15px'}
            pt={'10px'}
            p={'10px'}
            pb={'0'}
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
          >
            <RelatedTypeContractList
              type_counteragent_id={type_counteragent_id}
              typesContracts={typesContracts}
            />
            <Access
              permissions={[accessDict.update_read]}
              module={modulesDict.reference_books}
            >
              <CreateRelatedTypeContract
                type_counteragent_id={type_counteragent_id}
                excludedTypeContractIds={typesContracts?.map(
                  (item) => item.type_contract_id,
                )}
              />
            </Access>
          </Box>
          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <Flex mt={'15px'}>
              {isDirty && isEdit && (
                <Box>
                  <SubmitCancelButtons
                    isDirty={isDirty}
                    isUpdating={isUpdatingTypeCounteragent}
                    handleCancel={handleResetForm}
                  />
                </Box>
              )}
              {isEdit && (
                <Box ml={isDirty ? '10px' : 'none'}>
                  <DeleteTypeCounteragents
                    type_counteragent_id={type_counteragent_id}
                    short_name={short_name}
                    setExpandedIds={setExpandedIds}
                  />
                </Box>
              )}
            </Flex>
          </Access>
        </CardBox>
      </Box>
    )
  },
)
