import { useCallback, useEffect } from 'react'
import { Controller } from 'react-hook-form'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Box,
  FormControl,
  useToast,
  Card,
  Grid,
} from '@chakra-ui/react'

import { useGetUsersPermissions } from 'entities/usersPermissions'
import { toasts } from 'shared/utils'

import {
  CardBox,
  SelectInput,
  TextLabelTitle,
  TextTitle,
  TextValueTitle,
} from 'shared/ui'

import {
  accessDict,
  accessTranslateDict,
  accessTypeDict,
  inheritanceTypeDict,
} from 'shared/dictionary'

import {
  EditUserPermissionFormData,
  useEditUserPermissionForm,
} from './UseEditUserPermissionForm'

import { useUpdateUserPermission } from '../models/services/useUpdateUserPermission'
import { accordionModulesSettings } from '../models/utils/gteAcessData'

// misc
import { ChevronDownIcon } from '@chakra-ui/icons'

interface SelectOption {
  value: string
  label: string
}

interface HandleChangeArgs {
  moduleName: keyof EditUserPermissionFormData
  option: { value?: string } | null
  type: AccessType
}

interface EditUserPermissionProps {
  userId: string
  isCurrentUserAbleToEditAccess: boolean
  title?: string
}

enum AccessType {
  access = 'access',
  inheritance = 'inheritance',
}

const getBackgroundColor = {
  [accessDict.full_access]: '#C6F4EA',
  [accessDict.partial_access]: '#daf4fd',
  [accessDict.no_access]: '#FBEBEB',
  [accessDict.read]: '#FBEBEB',
  [accessDict.update_read]: '#f1f4c6',
}

const ItemText: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  return <TextTitle size="small">{children}</TextTitle>
}

export const EditUserPermission: React.FC<EditUserPermissionProps> = (
  props,
): JSX.Element => {
  const {
    userId,
    isCurrentUserAbleToEditAccess,
    title = 'Доcтупы пользователя',
  } = props

  const { userModuleRights, transformedData } = useGetUsersPermissions({
    userId,
    enabled: !!userId,
  })

  const { handleSubmit, errors, reset, control, watch } =
    useEditUserPermissionForm()

  const toast = useToast()

  const { mutate: updateUserPermissions, isLoading: isLoadingUpdate } =
    useUpdateUserPermission()

  useEffect(() => {
    if (userModuleRights && transformedData) {
      const initialValues = Object.keys(transformedData).reduce(
        (acc, moduleName) => {
          const moduleData = transformedData[moduleName]
          acc[moduleName] = {
            access: moduleData.access,
            inheritance: moduleData.inheritance,
          }
          return acc
        },
        {},
      )
      reset(initialValues)
    }
  }, [userModuleRights, reset])

  const fieldsValues = watch()

  const handleResetForm = () => reset()

  const handleChange = useCallback(
    async ({ moduleName, option, type }: HandleChangeArgs) => {
      const newValue = option ? option.value : ''

      const originalModuleData = transformedData[moduleName]?.originalData

      if (!originalModuleData) {
        console.error('No original data found for module:', moduleName)
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Данные для модулей ошибочные',
        })
        handleResetForm()
        return
      }

      const accessIdValue =
        type === AccessType.access ? newValue : fieldsValues[moduleName].access
      const inheritTypeIdValue =
        type === AccessType.inheritance
          ? newValue
          : fieldsValues[moduleName].inheritance

      const updateData = {
        accessId: accessTypeDict[accessIdValue],
        inheritTypeId: inheritanceTypeDict[inheritTypeIdValue],
        moduleRightsId: originalModuleData.module_rights_id,
        moduleName: originalModuleData.module_name,
        userId,
      }

      const updatePromise = new Promise((resolve, reject) => {
        updateUserPermissions({
          ...updateData,
          successAction: () => resolve('Данные успешно обновлены'),
          errorAction: () => reject(new Error('Ошибка обновления данных')),
        })
      })

      toast.promise(updatePromise, {
        loading: {
          title: 'Обновление данных...',
          description: 'Пожалуйста, подождите.',
          position: 'bottom-left',
          // @ts-ignore
          status: 'success',
        },
        success: {
          title: 'Успешно!',
          description: (
            <p>
              Доступы в модуле{' '}
              <span
                style={{ fontWeight: 'bold', fontSize: '18px' }}
              >{`${originalModuleData.module_name}`}</span>{' '}
              обновлены
            </p>
          ),

          position: 'bottom-left',
          // @ts-ignore
          status: 'info',
        },
        error: { title: 'Ошибка!', description: 'Не удалось обновить данные.' },
      })
    },
    [updateUserPermissions, transformedData, fieldsValues],
  )

  const generateFormField = (module, isLast: boolean) => {
    return (
      <Box
        // borderBottom={isLast ? 'none' : '1px solid #dfe0eb'}
        pb={'5px'}
        mt={'8px'}
        key={module.moduleName}
      >
        <CardBox
          //  w="980px"
          w="100%"
          m="10px 0"
        >
          <Box p="10px">
            <ItemText>{module.label}</ItemText>
          </Box>

          <hr style={{ margin: 0 }} />
          <Flex alignItems={'center'} mr={'25px'} p="10px">
            <FormControl
              id={`${module.moduleName}.access`}
              isInvalid={!!errors[module.moduleName]?.access}
            >
              <Box ml={'10px'} w="100%">
                <Card
                  w="100%"
                  boxShadow={'none'}
                  variant={'outline'}
                  bg={
                    getBackgroundColor[
                      module.data.find(
                        (option) =>
                          option.value ===
                          fieldsValues[module.moduleName]?.access,
                      )?.value
                    ]
                  }
                  p="10px"
                >
                  <Flex alignItems={'flex-start'} flexDir={'column'}>
                    <TextLabelTitle mb={'2px'} mr={'8px'}>
                      Досутп:
                    </TextLabelTitle>

                    {!isCurrentUserAbleToEditAccess ? (
                      <Box
                        border={'1px solid #dfe0eb'}
                        borderRadius={'5px'}
                        p="3px 15px"
                        bg="white"
                        w={'100%'}
                      >
                        <TextValueTitle pt={'8px'}>
                          {fieldsValues[module.moduleName]?.access
                            ? module.data.find(
                                (option) =>
                                  option.value ===
                                  fieldsValues[module.moduleName]?.access,
                              )?.label
                            : 'Не задано'}
                        </TextValueTitle>
                      </Box>
                    ) : (
                      <Controller
                        // @ts-ignore
                        name={`${module.moduleName}.access`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        }) => (
                          <SelectInput
                            size={'sm'}
                            isReadOnly={
                              isLoadingUpdate || !isCurrentUserAbleToEditAccess
                            }
                            data={module.data}
                            placeholder="Выберите тип разрешения"
                            value={module.data.find(
                              (option) => option.value === value,
                            )}
                            onChange={(option) => {
                              if (
                                option.value ===
                                module.data.find(
                                  (option) => option?.value === value,
                                )?.value
                              ) {
                                return
                              }
                              onChange(option ? option.value : '')
                              handleChange({
                                moduleName: module.moduleName,
                                type: AccessType.access,
                                option,
                              })
                            }}
                            error={!!error}
                          />
                        )}
                      />
                    )}
                  </Flex>

                  <Text color="red.600">
                    {errors[module.moduleName]?.access?.message}
                  </Text>
                </Card>
              </Box>
            </FormControl>
          </Flex>
          <>
            {module.inheritanceData &&
              fieldsValues?.[module.moduleName]?.access ===
                accessDict.partial_access && (
                <>
                  <Flex
                    position={'relative'}
                    alignItems={'baseline'}
                    flexDirection={'column'}
                    p={'0px 23px 15px 20px'}
                    // border={'1px solid red'}
                  >
                    <Card
                      w="100%"
                      boxShadow={'none'}
                      variant={'outline'}
                      bg={
                        getBackgroundColor[
                          module.data.find(
                            (option) =>
                              option.value ===
                              fieldsValues[module.moduleName]?.access,
                          )?.value
                        ]
                      }
                      p="10px"
                    >
                      <Flex>
                        <FormControl
                          id={`${module.moduleName}.inheritance`}
                          isInvalid={!!errors[module.moduleName]?.inheritance}
                        >
                          <TextLabelTitle mb={'5px'}>
                            Вид доступа по наследованию:
                          </TextLabelTitle>

                          {!isCurrentUserAbleToEditAccess ? (
                            <Text fontSize={'18px'}>
                              {fieldsValues[module.moduleName]?.inheritance
                                ? module.inheritanceData.find(
                                    (option) =>
                                      option.value ===
                                      fieldsValues[module.moduleName]
                                        ?.inheritance,
                                  )?.label
                                : 'Не задано'}
                            </Text>
                          ) : (
                            <Controller
                              // @ts-ignore
                              name={`${module.moduleName}.inheritance`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <SelectInput
                                  size={'sm'}
                                  isReadOnly={
                                    isLoadingUpdate ||
                                    !isCurrentUserAbleToEditAccess
                                  }
                                  data={module.inheritanceData}
                                  placeholder="Выберите тип разрешения"
                                  isClearable={false}
                                  value={module.inheritanceData.find(
                                    (option) => option.value === value,
                                  )}
                                  onChange={(option) => {
                                    if (
                                      option.value ===
                                      module.inheritanceData.find(
                                        (option) => option?.value === value,
                                      )?.value
                                    ) {
                                      return
                                    }

                                    onChange(option ? option.value : '')
                                    handleChange({
                                      moduleName: module.moduleName,
                                      type: AccessType.inheritance,
                                      option,
                                    })
                                  }}
                                  error={!!errors.counter_agents?.inheritance}
                                />
                              )}
                            />
                          )}

                          <Text color="red.600">
                            {errors[module.moduleName]?.inheritance?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Card>
                  </Flex>
                </>
              )}
          </>
        </CardBox>
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(null)}>
        <CardBox>
          <Box p="10px">
            <TextTitle size="small"> {title} </TextTitle>
          </Box>
          <hr style={{ margin: 0 }} />
          <Box p="10px">
            <Box
              mt="15px"
              borderLeft={'1px solid #dfe0eb'}
              borderRight={'1px solid #dfe0eb'}
              bg="#f7f8fb79"
            >
              <>
                <Accordion
                  allowMultiple
                  width="100%"
                  rounded="lg"
                  display="block"
                >
                  {accordionModulesSettings.map((tab) => (
                    <AccordionItem key={tab.tabLabel}>
                      <AccordionButton
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                        bg={'#ebeeff3c'}
                      >
                        <Text fontSize="14px" fontWeight={'700'}>
                          {tab.tabLabel}
                        </Text>
                        <ChevronDownIcon />
                      </AccordionButton>

                      <AccordionPanel p="0 15px">
                        <Grid
                          templateColumns="repeat(4, 1fr)"
                          columnGap={'15px'}

                          // border={'1px solid red'}
                        >
                          {tab.modules.map((module, index) => {
                            return generateFormField(
                              module,
                              index === tab.modules.length - 1,
                            )
                          })}
                        </Grid>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </>
            </Box>
          </Box>
        </CardBox>
      </form>
    </Box>
  )
}
