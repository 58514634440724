import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import { debounce } from 'lodash'

import {
  useGetAllGroupDelivery,
  useGetAllJuridicalPersons,
} from 'entities/referenceBooks'
import {
  DatePickerField,
  EditButton,
  FormInputBlock,
  PopoverMessage,
  SelectInputForm,
  SelectInputMultiForm,
  SubmitCancelButtons,
  TextLabelTitle,
  TextTitle,
} from 'shared/ui'
import { DeliveryPoint } from 'shared/models'

import { useAddNewGroupDelivery } from '../models/services/useAddNewGroupDelivery'
import { useAddNewGroupDeliveryForm } from './UseAddNewGroupDeliveryForm'
import { checkPeriodsOverlap } from '../utils/checkPeriodsOverlap'

// misc
import { HiMiniPlusCircle } from 'react-icons/hi2'

interface GroupDeliveryPeriods {
  date_from: string
  date_to: string
  id: string
}

interface AddNewGroupDeliveryProps {
  groupDelivery: DeliveryPoint[]
  gs_activity_areas_id: string
}

export const AddNewGroupDelivery: React.FC<AddNewGroupDeliveryProps> =
  React.memo((props) => {
    const { gs_activity_areas_id, groupDelivery = [] } = props

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const { juridicalPersonsData } = useGetAllJuridicalPersons({
      enabled: true,
    })

    const [errorPoints, setErrorPoints] = useState<{
      isError: boolean
      gd_point_names?: string[]
    }>({
      isError: false,
      gd_point_names: [],
    })

    const [isOpenNotification, setIsOpenNotification] = useState<{
      isOpen: boolean
      message: string
    }>({
      isOpen: false,
      message: '',
    })

    const { groupDeliveryData } = useGetAllGroupDelivery({
      enabled: true,
    })

    const { mutate: createGroupDelivery, isLoading: isCreatingGroupDelivery } =
      useAddNewGroupDelivery()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      watchedFields,
      watch,
      setValue,
      setError,
      isDirty,
    } = useAddNewGroupDeliveryForm()

    const dateFrom = watch('date_from')
    const dateTo = watch('date_to')

    const groupDeliveryPeriods: GroupDeliveryPeriods[] = useMemo(
      () =>
        groupDelivery.map((item) => ({
          date_from: item.date_from,
          date_to: item.date_to,
          id: item.id,
        })),
      [groupDelivery],
    )

    useEffect(() => {
      if (groupDelivery.length === 0)
        reset({
          date_from: moment('01.01.2010').format('DD.MM.yyyy'),
        })
      else
        reset({
          date_from: null,
        })
    }, [groupDelivery.length])

    useEffect(() => {
      setErrorPoints({ isError: false, gd_point_names: [] })
    }, [dateFrom, dateTo])

    useEffect(() => {
      checkPeriods()
    }, [dateFrom, dateTo, groupDeliveryPeriods])

    useEffect(() => {
      const currentGdPointNames = watch('gd_point_names')
      if (errorPoints?.gd_point_names && currentGdPointNames) {
        const updatedGdPointNames = currentGdPointNames.map((item) => ({
          ...item,
          isErrored: errorPoints?.gd_point_names.includes(item.label),
        }))
        setValue('gd_point_names', updatedGdPointNames, {
          shouldValidate: true,
        })
      }
    }, [errorPoints?.gd_point_names, setValue, watch])

    useEffect(() => {
      if (errorPoints?.gd_point_names?.length > 0) handleSetError()
    }, [errorPoints?.gd_point_names])

    const handleSetError = debounce(() => {
      setError('gd_point_names', {
        type: 'custom',
        message: 'Проверьте ГТП',
      })
    }, 0)

    const checkPeriods = useCallback(() => {
      const newPeriod = {
        date_from: dateFrom
          ? moment(dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : null,
        date_to: dateTo
          ? moment(dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : null,
      }

      if (newPeriod.date_from && newPeriod.date_to) {
        checkPeriodsOverlap(
          groupDeliveryPeriods,
          newPeriod,
          null,
          setIsOpenNotification,
        )
      }
    }, [dateFrom, dateTo, groupDeliveryPeriods, setIsOpenNotification])

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    const handleResetForm = useCallback(() => {
      reset()
      setErrorPoints({ isError: false, gd_point_names: [] })
    }, [reset])

    const onSubmit = ({
      date_from,
      date_to,
      gd_point_names,
      juridical_person,
    }) => {
      if (isOpenNotification.isOpen) return

      const pointNamesArray = gd_point_names.map((point) => point.label)
      const juridicalPerson = juridicalPersonsData.find(
        (item) => item.value === juridical_person,
      )

      createGroupDelivery({
        date_from: moment(date_from).format('YYYY-MM-DD'),
        date_to: date_to ? moment(date_to).format('YYYY-MM-DD') : null,
        gd_point_names: pointNamesArray,
        gs_activity_areas_id,
        juridical_person: juridicalPerson
          ? juridicalPerson.label
          : juridical_person
          ? juridical_person
          : null,
        successAction: () => {
          reset()
          setIsEdit(false)
        },
        errorAction(gd_point_names) {
          setErrorPoints({ isError: true, gd_point_names })
        },
      })
    }

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEdit ? (
          <Card
            mt={'15px'}
            w="1000px"
            border={'1px solid #d9d9d9'}
            transition={'all 0.2s ease-in-out'}
            _hover={{ border: '1px solid #11b8a2' }}
          >
            <Box p="10px">
              <TextTitle size="extraSmall">
                Добавление нового набора ГТП
              </TextTitle>
            </Box>

            <hr style={{ margin: 0 }} />
            <PopoverMessage
              triggerElement={<Box position={'absolute'} w={'300px'} />}
              isOpen={isOpenNotification.isOpen}
              message={isOpenNotification.message}
              title="Проверьте выбранный период"
              onClick={() =>
                setIsOpenNotification({ isOpen: false, message: null })
              }
            />
            <Card
              bg="#fdfdfd"
              p="10px"
              variant="outline"
              boxShadow={'none'}
              m="15px"
            >
              <Flex position={'relative'}>
                <Flex
                  w="100%"
                  justifyContent="flex-end"
                  position={'absolute'}
                  right={'5px'}
                  top={'0px'}
                >
                  <EditButton
                    handleEditClick={handleEditClick}
                    isEdit={isEdit}
                    size={'xs'}
                  />
                </Flex>
                <Box p={'10px'} w={'350px'}>
                  <FormInputBlock
                    valueWidth={'150px'}
                    titleWidth={'80px'}
                    allowEdit={true}
                    title={'Дата с'}
                    edit={true}
                  >
                    <DatePickerField
                      format="MM.yyyy"
                      maxDate={
                        dateTo ? moment(dateTo, 'DD.MM.yyyy').toDate() : null
                      }
                      showMonthYearPicker
                      name={'date_from'}
                      placeholder={'Дата от'}
                      type={'text'}
                      theme="monthPicker"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                  <Box m={'3px'} />
                  <FormInputBlock
                    titleWidth={'80px'}
                    allowEdit={true}
                    title={'Дата по'}
                    edit={true}
                  >
                    <DatePickerField
                      format="MM.yyyy"
                      minDate={
                        dateFrom
                          ? moment(dateFrom, 'DD.MM.yyyy').toDate()
                          : null
                      }
                      showMonthYearPicker
                      name={'date_to'}
                      placeholder={'Дата до'}
                      type={'text'}
                      theme="monthPicker"
                      {...commonInputProps}
                      isRequired={false}
                    />
                  </FormInputBlock>
                </Box>
                <Box p={'10px'} w={'500px'}>
                  <Flex alignItems={'center'}>
                    <TextLabelTitle w={'100px'}>ГТП: </TextLabelTitle>
                    <SelectInputMultiForm
                      isCreatable
                      isReadOnly={!isEdit}
                      name={'gd_point_names'}
                      data={groupDeliveryData}
                      placeholder="Выберите ГТП"
                      isClearable
                      isMulti
                      {...commonInputProps}
                    />
                  </Flex>
                  <Box m={'8px'} />
                  <Flex alignItems={'center'}>
                    <TextLabelTitle w={'100px'}>Юр. лицо: </TextLabelTitle>
                    <SelectInputForm
                      placeholder="Юр. лицо:"
                      getOptionValue={(option) => (option ? option.value : '')}
                      name="juridical_person"
                      data={juridicalPersonsData}
                      isClearable={true}
                      isCreatable={true}
                      notificationText="Новое юр. лицо будет создано"
                      {...commonInputProps}
                    />
                  </Flex>
                </Box>
              </Flex>
              {isEdit && (
                <Flex pt={'5px'}>
                  <SubmitCancelButtons
                    isDirty={isDirty}
                    isUpdating={isCreatingGroupDelivery}
                    handleCancel={handleResetForm}
                  />
                </Flex>
              )}
            </Card>
          </Card>
        ) : (
          <Box p={'20px 0'}>
            <Button
              position={'relative'}
              size={'xs'}
              bg="blue.700"
              color="white"
              display={'flex'}
              alignItems={'center'}
              onClick={() => setIsEdit(true)}
              _hover={{
                bg: 'blue.600',
              }}
            >
              <Box fontSize={'18px'} mr={'5px'}>
                <HiMiniPlusCircle />
              </Box>
              <Text position={'relative'}>Добавить набор ГТП</Text>
            </Button>
          </Box>
        )}
      </form>
    )
  })
