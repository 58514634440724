import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, Flex } from '@chakra-ui/react'

import { Access } from 'app/providers'
import {
  useGetAllEnergySystems,
  useGetAllGroupDelivery,
  useGetAllPriceZones,
  useGetAllSubjects,
  useGetDetailActivityAreas,
} from 'entities/referenceBooks'

import { accessDict, modulesDict } from 'shared/dictionary'
import {
  CardBox,
  EditButton,
  FormInputBlock,
  FormInputControl,
  Loader,
  MaskedInput,
  SelectInputForm,
  SubmitCancelButtons,
  TextLabelTitle,
  TextTitle,
  TextValueTitle,
} from 'shared/ui'

import {
  EditActivityFormData,
  useEditActivityForm,
} from './UseEditActivityForm'

import { useUpdateActivityArea } from '../models/services/useUpdateActivityArea'
import { GroupDelivery } from '../../editGroupDelivery'
import { DeleteActivityArea } from '../../deleteActivityArea'
import { AddNewGroupDelivery } from '../../addNewGroupDelivery'

interface ActivityItemProps {
  gs_activity_areas_id: string
  isExpanded: boolean
  setExpandedIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const ActivityItem: React.FC<ActivityItemProps> = React.memo((props) => {
  const { gs_activity_areas_id, isExpanded, setExpandedIds } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { data: activityArea, isLoading: isActivityAreaLoading } =
    useGetDetailActivityAreas({
      enabled: isExpanded && !!gs_activity_areas_id,
      gs_activity_areas_id,
    })

  const { mutate: updateActivityAria, isLoading: isUpdatingActivityArea } =
    useUpdateActivityArea()

  const { priceZonesData } = useGetAllPriceZones({
    enabled: true,
  })

  const { energySystemsData } = useGetAllEnergySystems({
    enabled: true,
  })

  const { subjectsData } = useGetAllSubjects({
    enabled: true,
  })

  useGetAllGroupDelivery({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
  } = useEditActivityForm()

  useEffect(() => {
    if (activityArea) {
      reset({
        area_name: activityArea.area_name,
        price_zone_id: activityArea.price_zone_id,
        ue_system_id: activityArea.ue_system_id,
        subject_id: activityArea.subject_id,
        time_zone: activityArea.time_zone,
      })
    }
  }, [activityArea, reset])

  const onSubmit = ({
    area_name,
    subject_id,
    price_zone_id,
    time_zone,
    ue_system_id,
  }: EditActivityFormData) => {
    updateActivityAria({
      successAction: () => {
        setIsEdit(false)
      },
      gs_activity_areas_id: activityArea.gs_activity_areas_id,
      area_name,
      price_zone_id,
      ue_system_id,
      subject_id,
      time_zone: String(time_zone),
    })
  }

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  if (isActivityAreaLoading)
    return (
      <Box m="150px 0">
        <Loader size="sm" />
      </Box>
    )

  return (
    <Box position={'relative'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBox w={'1000px'} position={'relative'} p="15px">
          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <Flex
              w="100%"
              justifyContent="flex-end"
              position={'absolute'}
              right={'20px'}
              top={'20px'}
              zIndex={1}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>
          </Access>

          <Card bg="#fdfdfd" p="10px" boxShadow={'none'} variant={'outline'}>
            <Flex alignItems={'center'}>
              <TextLabelTitle w={'212px'}>ID:</TextLabelTitle>
              <TextValueTitle size={'smallSource'}>
                {activityArea?.code_id}
              </TextValueTitle>
            </Flex>
            <Box m={'3px'} />
            <FormInputBlock
              titleWidth={'200px'}
              allowEdit={true}
              title={'Наименование:'}
              edit={isEdit}
              value={activityArea?.area_name}
            >
              <FormInputControl
                name="area_name"
                placeholder="Наименование"
                type="text"
                {...commonInputProps}
              />
            </FormInputBlock>
            <Box m={'3px'} />
            <FormInputBlock
              titleWidth={'200px'}
              allowEdit={true}
              title={'Ценовая зона:'}
              edit={isEdit}
              value={activityArea?.zone_name}
            >
              <SelectInputForm
                placeholder="Выберите зону"
                data={priceZonesData}
                isCreatable={false}
                isClearable={true}
                getOptionValue={(option) => (option ? option.value : '')}
                name="price_zone_id"
                {...commonInputProps}
              />
            </FormInputBlock>
            <Box m={'3px'} />
            <FormInputBlock
              titleWidth={'200px'}
              allowEdit={true}
              title={'ОЭС:'}
              edit={isEdit}
              value={activityArea?.system_name}
            >
              <SelectInputForm
                placeholder="Выберите ОЭС"
                getOptionValue={(option) => (option ? option.value : '')}
                name="ue_system_id"
                data={energySystemsData}
                isCreatable={false}
                isClearable={true}
                {...commonInputProps}
              />
            </FormInputBlock>
            <Box m={'3px'} />
            <FormInputBlock
              titleWidth={'200px'}
              allowEdit={true}
              title={'Субъект РФ:'}
              edit={isEdit}
              value={activityArea?.region_name}
            >
              <SelectInputForm
                placeholder="Субъект РФ"
                name="subject_id"
                data={subjectsData}
                isCreatable={false}
                isClearable={true}
                getOptionValue={(option) => (option ? option.value : '')}
                {...commonInputProps}
              />
            </FormInputBlock>
            <Box m={'3px'} />
            <Box w="450px">
              <FormInputBlock
                allowEdit={true}
                title={'Часовой пояс для расчета цен: МСК +'}
                edit={isEdit}
                value={activityArea?.time_zone}
                titleWidth={'260px'}
              >
                <MaskedInput
                  name={'time_zone'}
                  mask={'99'}
                  placeholder={'Часовой пояс'}
                  onChangeFormatter={(inputValue: string) =>
                    inputValue.replace(/\D/g, '')
                  }
                  type={'text'}
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
          </Card>

          <Flex mt={'15px'}>
            {isDirty && isEdit && (
              <Box>
                <SubmitCancelButtons
                  isDirty={isDirty}
                  isUpdating={isUpdatingActivityArea}
                  handleCancel={handleResetForm}
                />
              </Box>
            )}
            {isEdit && (
              <Box ml={isDirty ? '10px' : 'none'}>
                <DeleteActivityArea
                  gs_activity_areas_id={gs_activity_areas_id}
                  area_name={activityArea.area_name}
                  setExpandedIds={setExpandedIds}
                />
              </Box>
            )}
          </Flex>
        </CardBox>
      </form>
      <Box mt={'15px'} pt={'10px'} borderTop={'1px solid #dfe0eb'}>
        <TextTitle size="extraSmall">Связанные ГТП:</TextTitle>
        <Card
          border={'1px solid #dfe0eb'}
          borderRadius={'5px'}
          mt={'10px'}
          transition={'all 0.2s ease-in-out'}
          _hover={{ border: '1px solid #11b8a2' }}
          w={'1000px'}
        >
          {activityArea?.related_group_delivery_points.length > 0 ? (
            <GroupDelivery
              groupDelivery={activityArea?.related_group_delivery_points}
              gs_activity_areas_id={gs_activity_areas_id}
            />
          ) : (
            <Box p={'15px'}>
              <TextLabelTitle>Отсутствуют</TextLabelTitle>
            </Box>
          )}
        </Card>
        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <AddNewGroupDelivery
            groupDelivery={activityArea?.related_group_delivery_points}
            gs_activity_areas_id={gs_activity_areas_id}
          />
        </Access>
      </Box>
    </Box>
  )
})
