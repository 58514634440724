import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicSumColumn,
  BasicTitle,
} from 'shared/ui'

const size = 'lg'

export const TableStructure = () => {
  const structure = [
    {
      title: () => <BasicTitle size={size}>Базовый элемент</BasicTitle>,
      accessor: 'base_element_name',
      disableSortBy: true,
      minWidth: 120,
      width: 120,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicCommonColumn size={size}>
            {row.original.base_element_name}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Вид тарифа</BasicTitle>,
      accessor: 'kind_tariff_name',
      disableSortBy: true,
      minWidth: 120,
      width: 120,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicCommonColumn size={size}>
            {row.original.kind_tariff_name}
          </BasicCommonColumn>
        )
      },
    },
  ]

  return structure
}
