import { AxiosPromise } from 'axios'

import {
  PriceCategoriesResponse,
  SalesAllowancesResponse,
  TariffTransmissionServiceResponse,
  DayZonesResponse,
  ProductsResponse,
  DetailsCalculationPriceResponse,
  PriceATCResponse,
  ListSvncemResponse,
  ListSalesAllowanceResponse,
  TransServiceResponse,
  OthersResponse,
  UsersTariffResponse,
  ProductsThreeResponse,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class PriceCategoriesService extends ServiceBase {
  protected static BASE_PATH = 'ref_books_price_categories'

  public static getAllPriceCategories(): AxiosPromise<
    PriceCategoriesResponse[]
  > {
    return this.get<PriceCategoriesResponse[]>(
      `${this.BASE_PATH}/list_price_categories`,
    )
  }

  public static getSalesAllowances(): AxiosPromise<SalesAllowancesResponse[]> {
    return this.get<SalesAllowancesResponse[]>(
      `${this.BASE_PATH}/list_sales_allowances`,
    )
  }

  public static getTariffTransmission(): AxiosPromise<
    TariffTransmissionServiceResponse[]
  > {
    return this.get<TariffTransmissionServiceResponse[]>(
      `${this.BASE_PATH}/list_tariffs_transmission_services`,
    )
  }

  public static getDayZones(): AxiosPromise<DayZonesResponse[]> {
    return this.get<DayZonesResponse[]>(`${this.BASE_PATH}/list_day_zones`)
  }

  public static getProducts(): AxiosPromise<ProductsResponse[]> {
    return this.get<ProductsResponse[]>(`${this.BASE_PATH}/list_products`)
  }

  public static getDetailsCalculationPrice(): AxiosPromise<
    DetailsCalculationPriceResponse[]
  > {
    return this.get<DetailsCalculationPriceResponse[]>(
      `${this.BASE_PATH}/list_details_calculation_price_category`,
    )
  }

  public static getPriceATC(): AxiosPromise<PriceATCResponse[]> {
    return this.get<PriceATCResponse[]>(`${this.BASE_PATH}/list_types_prices`)
  }

  public static getListSvncem(): AxiosPromise<ListSvncemResponse[]> {
    return this.get<ListSvncemResponse[]>(`${this.BASE_PATH}/list_svncem`)
  }

  public static getListSalesAllowance(): AxiosPromise<
    ListSalesAllowanceResponse[]
  > {
    return this.get<ListSalesAllowanceResponse[]>(
      `${this.BASE_PATH}/list_tariff_sales_margin`,
    )
  }

  public static getListTransService(): AxiosPromise<TransServiceResponse[]> {
    return this.get<TransServiceResponse[]>(
      `${this.BASE_PATH}/list_base_transmission_services`,
    )
  }

  public static getListOthers(): AxiosPromise<OthersResponse[]> {
    return this.get<OthersResponse[]>(`${this.BASE_PATH}/list_others`)
  }

  public static getUserTariffs(): AxiosPromise<UsersTariffResponse[]> {
    return this.get<UsersTariffResponse[]>(
      `${this.BASE_PATH}/list_tariff_users`,
    )
  }

  public static getProductsThree(): AxiosPromise<ProductsThreeResponse[]> {
    return this.get<ProductsThreeResponse[]>(`${this.BASE_PATH}/trees`)
  }
}
