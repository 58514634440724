import { useRef } from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'

import { CompaniesList } from 'widgets'
import { ScrollToTop } from 'shared/ui'

export const CompaniesPage = () => {
  const scrollContainerRef = useRef(null)

  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'1fr'}
      gridTemplateColumns={'1fr'}
      height="89vh"
      overflow={'100'}
      gap="1"
      color="blackAlpha.700"
      fontWeight="bold"
      bg="#f7f8fb79"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />

      <GridItem area={'main'}>
        <Box
          ref={scrollContainerRef}
          style={{
            width: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(100vh - 96px)',
            // paddingBottom: '50px',
          }}
        >
          <Box
            borderBottom={'1px solid #dfe0eb'}
            p={'10px 25px 25px 25px'}
            mb="10px"
          >
            <CompaniesList />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  )
}
