import { useQuery } from 'react-query'

import { UsersTariffResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface UsersTariffData {
  value: string
  label: string
}

interface UseGetUsersTariffResult {
  data: UsersTariffResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  usersTariffData: UsersTariffData[] | undefined
}

interface UseGetUsersTariff {
  enabled: boolean
}

export function useGetUsersTariff({
  enabled,
}: UseGetUsersTariff): UseGetUsersTariffResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    UsersTariffResponse[]
  >({
    queryKey: [queryKeys.priceCategories.usersTariffs.allUsersTariffs],
    queryFn: () =>
      PriceCategoriesService.getUserTariffs().then((response) => response.data),
    enabled,
    retry: false,
  })

  const usersTariffData = data?.map((x) => ({
    value: x.tariff_user_id,
    label: `${x.base_element_name} `,
  }))

  return { data, isError, isFetching, isLoading, usersTariffData }
}
