import { useState } from 'react'
import Select from 'react-select'

// Функция для генерации дерева
const generateTree = (
  type = 'fixed',
  parentPrefix = 'el',
  maxDepth = 5,
  maxChildCount = 5,
) => {
  const randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min

  const createNode = (prefix, level, depth) => {
    if (level > depth) return null

    const children = []
    const childCount =
      type === 'random' ? randomInt(1, maxChildCount) : maxChildCount

    for (let i = 1; i <= childCount; i++) {
      const id = `${prefix}_${i}`
      const child = {
        id,
        label: `Node ${id}`,
        value: id,
        children: createNode(
          id,
          level + 1,
          type === 'random' ? randomInt(level, maxDepth) : depth,
        ),
      }
      children.push(child)
    }
    return children
  }

  const depth = type === 'random' ? randomInt(1, maxDepth) : maxDepth

  return createNode(parentPrefix, 1, depth)
}

// Математические операторы
const operators = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
  { label: '*', value: '*' },
  { label: '/', value: '/' },
]

const treeData = generateTree('random') // Генерация дерева

export const TreeSelectExample = () => {
  const [steps, setSteps] = useState([]) // Последовательность операций
  const [currentOptions, setCurrentOptions] = useState(treeData) // Текущие доступные опции
  const [isOperatorMode, setIsOperatorMode] = useState(false) // Режим выбора оператора

  const updateOptions = (steps) => {
    if (steps.length === 0) {
      setCurrentOptions(treeData) // Если шагов нет, возвращаем корень дерева
      setIsOperatorMode(false)
      return
    }

    const lastStep = steps[steps.length - 1]

    if (operators.some((op) => op.label === lastStep.label)) {
      setCurrentOptions(treeData) // После выбора оператора доступны корневые узлы
      setIsOperatorMode(false)
    } else if (lastStep.children) {
      setCurrentOptions(lastStep.children) // Если выбран узел дерева, отображаем дочерние узлы
      setIsOperatorMode(false)
    } else {
      setCurrentOptions(operators) // Если узел завершён, переключаемся на выбор операторов
      setIsOperatorMode(true)
    }
  }

  const handleChange = (selectedOptions) => {
    if (!selectedOptions) {
      setSteps([])
      setCurrentOptions(treeData) // Сбрасываем опции на корневые узлы
      return
    }

    if (selectedOptions.length < steps.length) {
      // Удаление узла
      setSteps(selectedOptions)
      updateOptions(selectedOptions)
      return
    }

    const lastSelected = selectedOptions[selectedOptions.length - 1]

    if (isOperatorMode) {
      if (operators.some((op) => op.label === lastSelected.label)) {
        setSteps([...selectedOptions]) // Добавляем оператор
        setCurrentOptions(treeData) // После оператора доступны корневые узлы
        setIsOperatorMode(false)
      }
    } else {
      setSteps([...selectedOptions]) // Добавляем узел
      updateOptions([...selectedOptions])
    }
  }

  const formatSelectedNodes = () => {
    const paths = []
    let currentPath = []
    steps.forEach((step) => {
      if (operators.some((op) => op.label === step.label)) {
        paths.push(currentPath.join(' → ')) // Завершаем текущую ветку
        paths.push(step.label) // Добавляем оператор
        currentPath = []
      } else {
        currentPath.push(step.label)
      }
    })
    if (currentPath.length > 0) {
      paths.push(currentPath.join(' → ')) // Добавляем последнюю ветку
    }
    return paths
  }

  return (
    <div>
      <h1>Tree Select Example</h1>
      <Select
        isMulti
        value={steps}
        options={currentOptions}
        onChange={handleChange}
        placeholder="Выберите узел или оператор"
        isClearable={false}
        closeMenuOnSelect={false}
      />
      <div>
        <h3>Выбранные узлы:</h3>
        <ul>
          {formatSelectedNodes().map((path, index) => (
            <li key={index}>{path}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
