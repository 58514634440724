import { useState, memo, useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import {
  OthersList,
  SalesAllowanceList,
  SvncemList,
  TransServiceList,
  useGetAllSvncem,
  useGetOthers,
  useGetSalesAllowance,
  useGetTransService,
  useGetUsersTariff,
  UsersTariffsList,
} from 'entities/priceCategories'

import { ElementsCKType } from '../types/types'

export const ElementsCK: React.FC = memo((): JSX.Element => {
  const [elementsCKType, setElementsCKType] = useState<ElementsCKType>(
    ElementsCKType.svncem,
  )

  const { data: svncemData, isLoading: isLoadingSvncem } = useGetAllSvncem({
    enabled: true,
  })

  const { data: salesAllowanceData, isLoading: isLoadingSalesAllowance } =
    useGetSalesAllowance({
      enabled: true,
    })

  const { data: transServiceData, isLoading: isLoadingTransService } =
    useGetTransService({
      enabled: true,
    })

  const { data: othersData, isLoading: isLoadingOthers } = useGetOthers({
    enabled: true,
  })

  const { data: usersTariffData, isLoading: isLoadingUsersTariff } =
    useGetUsersTariff({
      enabled: true,
    })

  const tabData = useMemo(
    () => [
      {
        type: ElementsCKType.svncem,
        label: 'СВНЦЭМ',
        content: <SvncemList data={svncemData} loading={isLoadingSvncem} />,
      },
      {
        type: ElementsCKType.transferServices,
        label: 'Услуга по передаче',
        content: (
          <TransServiceList
            data={transServiceData}
            loading={isLoadingTransService}
          />
        ),
      },
      {
        type: ElementsCKType.saleAllowance,
        label: 'Сбытовая надбавка',
        content: (
          <SalesAllowanceList
            data={salesAllowanceData}
            loading={isLoadingSalesAllowance}
          />
        ),
      },
      {
        type: ElementsCKType.others,
        label: 'Прочее',
        content: <OthersList data={othersData} loading={isLoadingOthers} />,
      },
      {
        type: ElementsCKType.custom,
        label: 'Пользовательские',
        content: (
          <UsersTariffsList
            data={usersTariffData}
            loading={isLoadingUsersTariff}
          />
        ),
      },
    ],
    [
      svncemData,
      isLoadingSvncem,
      salesAllowanceData,
      isLoadingSalesAllowance,
      transServiceData,
      isLoadingTransService,
      othersData,
      isLoadingOthers,
      usersTariffData,
      isLoadingUsersTariff,
    ],
  )

  const handleTabChange = useCallback((type: ElementsCKType) => {
    setElementsCKType(type)
  }, [])

  return (
    <Box p="2">
      <Box mt="15px" p="2" border="1px solid #dfe0eb" borderRadius="5px">
        <Flex>
          {tabData.map((tab, index) => (
            <Button
              key={tab.type}
              size="xs"
              w="200px"
              borderRadius={0}
              border="1px solid #ececec"
              borderLeftRadius={index === 0 ? '5px' : 0}
              borderRightRadius={index === tabData.length - 1 ? '5px' : 0}
              _hover={{ bg: '#3285d2', color: 'white' }}
              bg={elementsCKType === tab.type ? '#2d70af' : '#f8f8f8'}
              color={elementsCKType === tab.type ? 'white' : 'black'}
              onClick={() => handleTabChange(tab.type)}
            >
              {tab.label}
            </Button>
          ))}
        </Flex>
        <Tabs
          index={tabData.findIndex((tab) => tab.type === elementsCKType)}
          isManual
        >
          <TabPanels>
            {tabData.map((tab) => (
              <TabPanel key={tab.type} padding={0}>
                {tab.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
})
