import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { UsersTariffResponse } from 'shared/models'
import { TableEntity } from './TableEntity'

interface UsersTariffsTableProps {
  tableData: UsersTariffResponse[]
  loading: boolean
}

export const UsersTariffsTable: React.FC<UsersTariffsTableProps> = memo(
  ({ tableData, loading }) => {
    return (
      <Box maxW={'1000px'}>
        <TableEntity tableData={tableData} loading={loading} />
      </Box>
    )
  },
)
