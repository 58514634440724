import { useQuery } from 'react-query'

import { ListSalesAllowanceResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface SalesAllowanceData {
  value: string
  label: string
}

interface UseGetSalesAllowanceResult {
  data: ListSalesAllowanceResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  salesAllowanceData: SalesAllowanceData[] | undefined
}

interface UseAllSvncem {
  enabled: boolean
}

export function useGetSalesAllowance({
  enabled,
}: UseAllSvncem): UseGetSalesAllowanceResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    ListSalesAllowanceResponse[]
  >({
    queryKey: [queryKeys.priceCategories.salesAllowance.allSalesAllowance],
    queryFn: () =>
      PriceCategoriesService.getListSalesAllowance().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const salesAllowanceData = data?.map((x) => ({
    value: x.tariff_sales_margin_id,
    label: `${x.base_element_name} `,
  }))

  return { data, isError, isFetching, isLoading, salesAllowanceData }
}
