import { groupBy } from 'lodash'

export const rawData = [
  {
    product_id: '060ee56a-5f21-4f46-adae-5637a3cdf0f8',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Факт',
    kind_product_name: 'Всего',
  },
  {
    product_id: 'ddd1bdaf-3d08-43a1-b40e-10c455573b68',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Час',
    subtype_product_name: 'Факт',
    kind_product_name: 'Всего',
  },
  {
    product_id: 'de8d5b0c-c599-4130-ade7-c8888c363567',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'План',
    kind_product_name: 'Всего',
  },
  {
    product_id: '69e5f57e-2f0b-474c-a7af-0934e7736eb6',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Час',
    subtype_product_name: 'План',
    kind_product_name: 'Всего',
  },
  {
    product_id: '4940ffca-b551-48f4-9afd-bcc3304a27b4',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Факт',
    kind_product_name: 'Пик',
  },
  {
    product_id: 'd25a2b4c-6341-4c45-9e08-bdc13b63d225',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Факт',
    kind_product_name: 'Полупик',
  },
  {
    product_id: '459b4262-19b8-47a0-9eb6-2c676f39f41a',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Факт',
    kind_product_name: 'Ночь',
  },
  {
    product_id: '51b85b6f-4b1d-4110-b659-7aaa01daff11',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Факт',
    kind_product_name: 'День',
  },
  {
    product_id: '77a6418d-8a03-4c5e-b69d-6414c1e6ebab',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Час',
    subtype_product_name: 'Отклонение',
    kind_product_name: 'БР+',
  },
  {
    product_id: '71a52c1a-0da1-4ae1-9e7a-142ea987aae7',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Час',
    subtype_product_name: 'Отклонение',
    kind_product_name: 'БР-',
  },
  {
    product_id: 'd7c81deb-706f-49c3-81d7-07b0276fb34d',
    base_element_name: 'Товар',
    type_product_name: 'Электроэнергия',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Отклонение',
    kind_product_name: 'Всего',
  },
  {
    product_id: '0d77cc6d-10bf-4d20-bb79-a26f78ca4d6f',
    base_element_name: 'Товар',
    type_product_name: 'Мощность',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'ГП',
    kind_product_name: null,
  },
  {
    product_id: 'dc200ef1-6303-42bf-87aa-993ba3e5fc24',
    base_element_name: 'Товар',
    type_product_name: 'Мощность',
    calculation_period_name: 'Месяц',
    subtype_product_name: 'Сеть',
    kind_product_name: null,
  },
]

const groupData = (data) => {
  const groupByField = (data, field) => {
    return data.reduce((acc, item) => {
      const key = item[field] || 'N/A' // Устанавливаем 'N/A', если значение отсутствует
      if (!acc[key]) acc[key] = []
      acc[key].push(item)
      return acc
    }, {})
  }

  const buildTree = (data, fields) => {
    if (fields.length === 0) {
      // Если нет полей для группировки, возвращаем только конечные узлы
      return data
        .filter((item) => item.kind_product_name) // Убираем узлы с отсутствующим kind_product_name
        .map((item) => ({
          label: item.kind_product_name || 'N/A', // Устанавливаем 'N/A', если значение отсутствует
          value: item.product_id || `unknown-${Math.random()}`, // Генерируем значение, если отсутствует
        }))
    }

    const [currentField, ...remainingFields] = fields
    const grouped = groupByField(data, currentField)

    return Object.entries(grouped).map(([key, values]) => {
      const children = buildTree(values, remainingFields)
      return {
        label: key || 'N/A', // Устанавливаем 'N/A', если значение отсутствует
        value: key.toLowerCase() || `unknown-${Math.random()}`, // Генерируем значение, если отсутствует
        children: children.length > 0 ? children : undefined, // Убираем пустые children
      }
    })
  }

  // Порядок полей для группировки
  const fields = [
    'base_element_name',
    'type_product_name',
    'calculation_period_name',
    'subtype_product_name',
  ]

  return buildTree(data, fields)
}

export const mockGroupedData = groupData(rawData)
