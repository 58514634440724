import { useForm, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import * as z from 'zod'

const SCHEMA_VALIDATION = z.object({
  template_name: z.string().nonempty('Имя шаблона обязательно'),
  items: z
    .array(
      z.object({
        template_type: z.string().nonempty('Тип файла обязателен'),
        gs_activity_areas_id: z.string().nullable().optional(),
      }),
    )
    .min(1, 'Необходимо добавить хотя бы один элемент')
    .superRefine((items, ctx) => {
      let tariffFgcCount = 0

      items.forEach((item, index) => {
        if (item.template_type === 'tariff_fgc') {
          tariffFgcCount += 1
        }

        if (item.template_type !== 'tariff_fgc' && !item.gs_activity_areas_id) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['items', index, 'gs_activity_areas_id'],
            message:
              'Зона деятельности обязательна, если тип файла не Tariff FGC',
          })
        }
      })

      if (tariffFgcCount > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['items'],
          message: 'Можно добавить только один тип файла Tariff FGC',
        })
      }
    }),
})

export interface EditATCTemplateFormData {
  template_name?: string
  items?: { template_type?: string; gs_activity_areas_id?: string }[]
}

export const useEditATCTemplateForm = () => {
  const { register, handleSubmit, formState, control, watch, reset, trigger } =
    useForm<EditATCTemplateFormData>({
      resolver: zodResolver(SCHEMA_VALIDATION),
      defaultValues: {
        items: [{ template_type: '', gs_activity_areas_id: '' }],
      },
      mode: 'all',
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const watchedFields = watch()
  const items = watch('items')
  const { isValid, errors, isDirty } = formState

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    itemsFields: fields,
    appendItem: append,
    removeItem: remove,
    watchedFields,
    reset,
    items,
    trigger,
  }
}
