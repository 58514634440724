import { useCallback, useEffect, useMemo, useState } from 'react'
import { map } from 'lodash'
import { Box, Button, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react'

import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { DeleteMonitoringTemplate } from 'features/prices'

import {
  CardBox,
  EditButton,
  FormInputBlock,
  FormInputControl,
  HeaderWithSkew,
  SelectInputForm,
  SubmitCancelButtons,
  TextTitle,
  UploadFileInfoView,
} from 'shared/ui'
import { GetMonitoringTemplateResponse } from 'shared/models'

import {
  EditMonitoringTemplateFormData,
  useEditMonitoringTemplateForm,
} from './EditMonitoringTemplateItemForm'
import { useUpdateMonitoringTemplate } from '../models/editMonitoringTemplateServie'

// misc
import { HiMiniPlusCircle, HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface EditATCTemplateItemProps extends GetMonitoringTemplateResponse {}

export const EditMonitorigTemplateItem: React.FC<EditATCTemplateItemProps> = (
  props,
): React.ReactElement => {
  const {
    added_at,
    gs_activity_areas_ids,
    monitoring_template_name,
    user_name,
  } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null)

  const { activityAreasData = [] } = useGetAllActivityAreas({
    enabled: true,
  })

  const { mutate: updateMonitoringTemplate, isLoading } =
    useUpdateMonitoringTemplate()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    isDirty,
    itemsFields,
    appendItem,
    removeItem,
    isValid,
    items,
  } = useEditMonitoringTemplateForm()

  useEffect(() => {
    if (monitoring_template_name && gs_activity_areas_ids) {
      reset({
        template_name: monitoring_template_name,
        items: gs_activity_areas_ids.map((i) => ({
          gs_activity_areas_ids: i,
        })),
      })
    }
  }, [monitoring_template_name, gs_activity_areas_ids])

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = useCallback(
    (submitProps: EditMonitoringTemplateFormData) => {
      const formattedItems = map(submitProps.items, 'gs_activity_areas_ids')

      updateMonitoringTemplate({
        old_template_name: monitoring_template_name,
        monitoring_template_name: submitProps.template_name,
        gs_activity_areas_ids: formattedItems,
        successAction: handleEditClick,
      })
    },
    [updateMonitoringTemplate, monitoring_template_name, handleEditClick],
  )

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const disableAddButton = items.some(
    (item) => item.gs_activity_areas_ids === '',
  )

  const uploadInfo = useMemo(
    () => ({
      user: user_name,
      added_at,
      inline: true,
    }),
    [user_name, added_at],
  )

  const getAvailableActivityAreas = (index) => {
    const selectedActivityZones = items.map(
      (item) => item.gs_activity_areas_ids,
    )
    return activityAreasData.filter(
      (area) =>
        !selectedActivityZones.includes(area.value) ||
        area.value === items[index].gs_activity_areas_ids,
    )
  }

  const handleCancel = useCallback(() => {
    reset()
  }, [reset])

  return (
    <CardBox p="10px" w="1000px">
      <Box position={'relative'}>
        <Card bg="#fdfdfd" p="10px" boxShadow={'none'} variant={'outline'}>
          <UploadFileInfoView {...uploadInfo} />
          <Box mt={'10px'} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              w="100%"
              justifyContent="flex-end"
              position={'absolute'}
              right={'10px'}
              top={'10px'}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>

            <Box maxW={'700Px'}>
              <Box mr={'35px'} w={'470px'}>
                {isEdit ? (
                  <FormInputBlock
                    titleWidth={'175px'}
                    allowEdit={isEdit}
                    title={'Название шаблона:'}
                    edit={isEdit}
                    value={monitoring_template_name}
                  >
                    <FormInputControl
                      name="template_name"
                      placeholder="Наименование"
                      type="text"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                ) : (
                  <HeaderWithSkew w={'350px'}>
                    <Box pl="10px">{monitoring_template_name}</Box>
                  </HeaderWithSkew>
                )}
              </Box>

              <Box mt={'5px'} />

              <Box
                border={'1px solid #dfe0eb'}
                borderRadius={'5px'}
                p={2}
                bg="white"
              >
                <SimpleGrid columns={1} spacing={2}>
                  {itemsFields.map((field, index) => {
                    const availableActivityAreas =
                      getAvailableActivityAreas(index)

                    return (
                      <Card
                        key={field.id}
                        bg={hoveredCardIndex === index ? 'red.50' : '#fdfdfd'}
                        p="5px"
                        boxShadow={'none'}
                        variant={'outline'}
                        _hover={{
                          border:
                            hoveredCardIndex === index
                              ? '1px solid red'
                              : '1px solid #11b8a2',
                        }}
                      >
                        <Box>
                          <Flex columnGap={'15px'}>
                            <FormInputBlock
                              titleWidth={'160px'}
                              title={'Зона деятельности:'}
                              allowEdit={true}
                              edit={isEdit}
                              value={
                                activityAreasData.find(
                                  (area) =>
                                    area.value ===
                                    items[index].gs_activity_areas_ids,
                                )?.label || ''
                              }
                            >
                              <SelectInputForm
                                placeholder="Зона деятельности"
                                data={availableActivityAreas}
                                name={`items.${index}.gs_activity_areas_ids`}
                                isClearable={true}
                                getOptionValue={(option) =>
                                  option ? option.value : ''
                                }
                                {...commonInputProps}
                              />
                            </FormInputBlock>
                            {isEdit && (
                              <Flex
                                alignItems={'flex-end'}
                                pb={'7px'}
                                ml="65px"
                                mr="10px"
                              >
                                <Flex
                                  justifyContent={'flex-end'}
                                  ml="15px"
                                  className="icon-delete"
                                  fontSize="20px"
                                  cursor="pointer"
                                  color="#c2c2c2"
                                  _hover={{
                                    cursor: 'pointer',
                                    color: '#bc343f',
                                  }}
                                  onClick={() => {
                                    removeItem(index)
                                    setHoveredCardIndex(null)
                                  }}
                                  onMouseEnter={() =>
                                    setHoveredCardIndex(index)
                                  }
                                  onMouseLeave={() => setHoveredCardIndex(null)}
                                >
                                  <HiOutlineArchiveBoxXMark />
                                </Flex>
                              </Flex>
                            )}
                          </Flex>
                        </Box>
                      </Card>
                    )
                  })}
                </SimpleGrid>
                {isEdit && (
                  <Box mt="15px">
                    <Button
                      w={'fit-content'}
                      mr="10px"
                      position={'relative'}
                      size={'xs'}
                      bg="blue.700"
                      color="white"
                      display={'flex'}
                      alignItems={'center'}
                      onClick={() => appendItem({ gs_activity_areas_ids: '' })}
                      isDisabled={disableAddButton}
                      _hover={{
                        bg: 'blue.600',
                      }}
                    >
                      <Box fontSize={'18px'} mr={'5px'}>
                        <HiMiniPlusCircle />
                      </Box>
                      <Text position={'relative'}>
                        Добавить зону деятельности
                      </Text>
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            {isDirty && isEdit && (
              <Box mt={'15px'}>
                <SubmitCancelButtons
                  isDirty={isDirty}
                  isUpdating={isLoading}
                  handleCancel={handleCancel}
                  isDisabled={!isValid || itemsFields.length === 0}
                />
              </Box>
            )}
          </form>
          {isEdit && (
            <Box mt={'15px'}>
              <DeleteMonitoringTemplate
                monitoring_template_name={monitoring_template_name}
              />
            </Box>
          )}
        </Card>
      </Box>
    </CardBox>
  )
}
