import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider, useDisclosure } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/react'
import { ConfigProvider } from 'antd'

import { WORK_MODE } from 'shared/constants'
import { antTheme } from 'shared/styles'
import { AppRouter, ModalProvider, queryClient } from './providers'
import { AccessProvider } from './providers/accessProvider'

function AppEntry() {
  const modalDisclosure = useDisclosure()
  const { ToastContainer } = createStandaloneToast()

  return (
    <AccessProvider>
      <ConfigProvider theme={antTheme}>
        <ChakraProvider>
          <QueryClientProvider client={queryClient}>
            <ModalProvider modalDisclosure={modalDisclosure}>
              <AppRouter />
            </ModalProvider>
            <ToastContainer />
            {import.meta.env.MODE === WORK_MODE.DEVELOPMENT && (
              <ReactQueryDevtools />
            )}
          </QueryClientProvider>
        </ChakraProvider>
      </ConfigProvider>
    </AccessProvider>
  )
}

export default AppEntry
