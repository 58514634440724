import React from 'react'
import { ProductsTable } from './ProductsTable/ProductsTable'
import { ProductsResponse } from 'shared/models'

export const PriceProductsListV2: React.FC<{
  data: ProductsResponse[]
  loading: boolean
}> = ({ data, loading }): JSX.Element => {
  return (
    <div>
      <ProductsTable tableData={data} loading={loading} />
    </div>
  )
}
