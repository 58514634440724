import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Text,
  Tooltip,
  Flex,
  IconButton,
} from '@chakra-ui/react'

import { normalizeValues, referenceGenerateUrl } from 'shared/utils'

import { Reference } from 'widgets/prices/monitoring/types/types'

//  misc
import { VscError } from 'react-icons/vsc'
import { FaCheckCircle } from 'react-icons/fa'
import {
  HiMiniArrowTopRightOnSquare,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

interface TableData {
  [data_type: string]: {
    [month: string]: {
      status: string
      message: string
      reference: Reference
    }
  }
}

interface MonitoringByMonthTableProps {
  data: TableData
  months: string[]
}

const COLUMN_WIDTH = 150
const FIRST_COLUMN_WIDTH = 300
const SCROLLABLE_DISTANCE = 200
const ENABLED_SCROLLABLE_LENGTH = 5

const TableText: React.FC<{ children: React.ReactNode }> = React.memo(
  ({ children }) => (
    <Text
      fontSize="12px"
      textTransform="capitalize"
      textAlign="center"
      whiteSpace="normal"
    >
      {children}
    </Text>
  ),
)

const TableTh: React.FC<{ children: React.ReactNode; sticky?: boolean }> =
  React.memo(({ children, sticky = false }) => (
    <Th
      bg="#f0f0f0"
      _hover={{ bg: '#e9e9e9' }}
      sx={{
        position: sticky ? 'sticky' : 'relative',
        top: 0,
        left: sticky ? 0 : 'auto',
        zIndex: sticky ? 2 : 1,
        borderRight: sticky ? '1px solid #e2e8f0' : 'none',
      }}
      minW={sticky ? `${FIRST_COLUMN_WIDTH}px` : `${COLUMN_WIDTH}px`}
      maxW={sticky ? `${FIRST_COLUMN_WIDTH}px` : `${COLUMN_WIDTH}px`}
      w={sticky ? `${FIRST_COLUMN_WIDTH}px` : `${COLUMN_WIDTH}px`}
    >
      <TableText>{children}</TableText>
    </Th>
  ))

const statusIcon = {
  success: (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      w={'100%'}
      h={'20px'}
      color={'green'}
      mr="10px"
      fontSize={'17px'}
    >
      <Box mr="5px">
        <FaCheckCircle />
      </Box>
    </Flex>
  ),
  warning: (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      w={'100%'}
      h={'24px'}
      color={'#fda026'}
      mr="10px"
      fontSize={'20px'}
    >
      <Box mr="5px">
        <HiOutlineExclamationTriangle />
      </Box>
    </Flex>
  ),
  error: (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      w={'100%'}
      h={'24px'}
      color={'red'}
      mr="10px"
      fontSize={'20px'}
    >
      <Box mr="5px">
        <VscError />
      </Box>
    </Flex>
  ),
}

export const MonitoringByMonthTable: React.FC<MonitoringByMonthTableProps> =
  React.memo(({ data, months }) => {
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const [showScrollLeft, setShowScrollLeft] = useState(false)
    const [showScrollRight, setShowScrollRight] = useState(false)

    const updateScrollButtons = useCallback(() => {
      const container = tableContainerRef.current
      if (container) {
        const isScrolledToLeft = container.scrollLeft === 0
        const isScrolledToRight =
          Math.ceil(container.scrollLeft + container.clientWidth) >=
          container.scrollWidth

        setShowScrollLeft(!isScrolledToLeft)
        setShowScrollRight(!isScrolledToRight)
      }
    }, [])

    useEffect(() => {
      if (months.length > ENABLED_SCROLLABLE_LENGTH) setShowScrollRight(true)
    }, [months.length])

    useEffect(() => {
      const container = tableContainerRef.current
      if (container) {
        container.addEventListener('scroll', updateScrollButtons)
        return () =>
          container.removeEventListener('scroll', updateScrollButtons)
      }
    }, [updateScrollButtons])

    const scrollTable = (direction: 'left' | 'right') => {
      if (tableContainerRef.current) {
        const scrollAmount =
          direction === 'left' ? -SCROLLABLE_DISTANCE : SCROLLABLE_DISTANCE
        tableContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        })
      }
    }

    const onOpen = ({ reference }) => {
      if (!reference) return
      const url = referenceGenerateUrl(reference)
      window.open(url, '_blank')
    }

    return (
      <Box position="relative" maxW="100%" border="1px solid #e2e8f0">
        {(showScrollRight || showScrollLeft) && (
          <Box position={'absolute'} zIndex={2} top={'-50px'} right={'50px'}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => scrollTable('left')}
              aria-label="Scroll Left"
              zIndex={4}
              isDisabled={!showScrollLeft}
              size={'sm'}
              mr={'5px'}
            />

            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => scrollTable('right')}
              aria-label="Scroll Right"
              zIndex={4}
              isDisabled={!showScrollRight}
              size={'sm'}
            />
          </Box>
        )}

        <TableContainer
          ref={tableContainerRef}
          maxH="57vh"
          height="57vh"
          overflowY="auto"
          overflowX="auto"
          position="relative"
          w="fit-content"
        >
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                <TableTh sticky>Тип данных</TableTh>
                {months.map((month) => (
                  <TableTh key={month}>
                    {normalizeValues(month, 'date')}
                  </TableTh>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(data).map(([dataType, monthData]) => (
                <Tr key={dataType}>
                  <Td
                    position="sticky"
                    left={0}
                    bg="white"
                    zIndex={1}
                    minW={`${FIRST_COLUMN_WIDTH}px`}
                    maxW={`${FIRST_COLUMN_WIDTH}px`}
                    w={`${FIRST_COLUMN_WIDTH}px`}
                    whiteSpace="normal"
                  >
                    <Box
                      position={'absolute'}
                      w={'100%'}
                      h={'1px'}
                      right={'0'}
                      top={'-1px'}
                      zIndex={-1}
                      background={'#edf2f7'}
                    />
                    <Box position={'relative'}>
                      <Box
                        position={'absolute'}
                        w={'1px'}
                        h={'60px'}
                        right={'-17px'}
                        top={'-9px'}
                        zIndex={3}
                        background={'#f8f8f8'}
                      />

                      {dataType}
                    </Box>
                  </Td>
                  {months.map((month) => {
                    const { status, message, reference } = monthData[month] || {
                      status: '',
                      message: '',
                      reference: null,
                    }
                    return (
                      <Tooltip
                        key={month}
                        label={
                          message && <Text textAlign="center">{message}</Text>
                        }
                        openDelay={250}
                        p={2}
                        maxW="200px"
                        hasArrow
                        placement="top"
                        borderRadius="5px"
                        bg={
                          status === 'warning'
                            ? '#fda026'
                            : status === 'error'
                            ? 'red'
                            : '#12bb31'
                        }
                      >
                        <Td
                          key={month}
                          textAlign="center"
                          minW={`${COLUMN_WIDTH}px`}
                          maxW={`${COLUMN_WIDTH}px`}
                          w={`${COLUMN_WIDTH}px`}
                          h={'50px'}
                          position={'relative'}
                          cursor={'pointer'}
                          role="group"
                        >
                          <Flex
                            w={'100%'}
                            h={'100%'}
                            top={0}
                            left={0}
                            justifyContent={'center'}
                            alignItems={'center'}
                            position={'relative'}
                          >
                            {(status === 'warning' || status === 'error') && (
                              <Flex
                                justifyContent={'center'}
                                alignItems={'center'}
                                position={'absolute'}
                                w={'40px'}
                                h={'20px'}
                                right={'15px'}
                                borderRadius={'3px'}
                                cursor={'pointer'}
                                _hover={{
                                  border: '1px solid #cfcfcf',
                                  backgroundColor: 'gray.100',
                                  boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
                                }}
                                zIndex={1}
                                onClick={() => onOpen({ reference })}
                              >
                                <Box fontSize={'15px'} color={'black'}>
                                  <HiMiniArrowTopRightOnSquare />
                                </Box>
                              </Flex>
                            )}
                            <Box
                              className="hover-box"
                              position={'absolute'}
                              w={'199px'}
                              h={'50px'}
                              top={'-8px'}
                              left={'-16px'}
                              border={'1px solid '}
                              borderColor={
                                status === 'warning'
                                  ? '#fda026'
                                  : status === 'error'
                                  ? 'red'
                                  : '#12bb31'
                              }
                              display="none"
                              _groupHover={{ display: 'block' }}
                            />
                            {statusIcon[status]}
                          </Flex>
                        </Td>
                      </Tooltip>
                    )
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  })
