import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { ListSalesAllowanceResponse } from 'shared/models'
import { TableEntity } from './TableEntity'

interface SalesAllowanceTable {
  tableData: ListSalesAllowanceResponse[]
  loading: boolean
}

export const SalesAllowanceTable: React.FC<SalesAllowanceTable> = memo(
  ({ tableData, loading }) => {
    return (
      <Box maxW={'600px'}>
        <TableEntity tableData={tableData} loading={loading} />
      </Box>
    )
  },
)
