import { Card, CardProps } from '@chakra-ui/react'
import { ReactNode, forwardRef } from 'react'

interface CardBoxProps extends CardProps {
  children: ReactNode
}

export const CardBox = forwardRef<HTMLDivElement, CardBoxProps>(
  (props, ref) => {
    const { children, ...rest } = props

    return (
      <Card
        border={'1px solid transparent'}
        transition={'all 0.2s ease-in-out'}
        _hover={{
          border: '1px solid #11b8a284',
          // border: '1px solid #ececec84',
          shadow: 'sm',
        }}
        {...rest}
        ref={ref}
      >
        {children}
      </Card>
    )
  },
)
