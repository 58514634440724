/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'

import { BasicTable } from 'shared/ui'
import { ListSvncemResponse } from 'shared/models'
import { TableStructure } from './TableStructure'

interface ITableEntityProps {
  tableData: ListSvncemResponse[]
  loading: boolean
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { tableData = [], loading } = props

  const columns = TableStructure()

  const data = useMemo(() => tableData, [tableData])

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '65vh',
        tableWidth: '800',
      },
      data,
      columns,
      emptyText: 'Данные отсутствуют',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      smallHeader: false,
    }),
    [data, columns],
  )

  return (
    <>
      <BasicTable {...tableProps} loading={loading} />
    </>
  )
}
