import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, Flex } from '@chakra-ui/react'
import moment from 'moment'
import { debounce } from 'lodash'

import { Access } from 'app/providers'
import { useGetAllJuridicalPersons } from 'entities/referenceBooks'
import { accessDict, modulesDict } from 'shared/dictionary'
import { convertDateForAPI, formatDateWithNominativeMonth } from 'shared/utils'
import {
  DatePickerField,
  EditButton,
  FormInputBlock,
  FormInputControl,
  PopoverMessage,
  SelectInputForm,
  SelectInputMultiForm,
  SubmitCancelButtons,
  TextLabelTitle,
} from 'shared/ui'

import { useEditGroupDeliveryForm } from './UseEditGroupDeliveryForm'
import { useUpdateGroupDelivery } from '../models/services/useUpdateGroupDelivery'
import { DeleteGroupDelivery } from '../../deleteGroupDelivery'
import { checkPeriodsOverlap } from '../utils/checkPeriodsOverlap'

interface GroupDeliveryPeriods {
  date_from: string
  date_to: string
  id: string
}

interface GroupDeliveryProps {
  groupDeliveryData: {
    value: string
    label: string
  }[]
  gd_point_names: string[]
  date_from: string
  id: string
  date_to: string
  gs_activity_areas_id: string
  juridical_person: string
  groupDeliveryPeriods: GroupDeliveryPeriods[]
}

export const EditGroupDelivery: React.FC<GroupDeliveryProps> = React.memo(
  (props) => {
    const {
      groupDeliveryPeriods,
      gd_point_names,
      date_from,
      date_to,
      groupDeliveryData,
      gs_activity_areas_id,
      juridical_person,
      id,
    } = props

    const { data: juridicalPersonsData } = useGetAllJuridicalPersons({
      enabled: true,
    })

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isOpenNotification, setIsOpenNotification] = useState<{
      isOpen: boolean
      message: string
    }>({
      isOpen: false,
      message: '',
    })

    const [errorPoints, setErrorPoints] = useState<{
      isError: boolean
      gd_point_names?: string[]
    }>({
      isError: false,
      gd_point_names: [],
    })
    const [filteredData, setFilteredData] = useState<
      GroupDeliveryProps['groupDeliveryData']
    >([])

    const { mutate: updateGroupDelivery, isLoading: isUpdatingGroupDelivery } =
      useUpdateGroupDelivery()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      watchedFields,
      watch,
      setValue,
      getValues,
      isDirty,
      setError,
    } = useEditGroupDeliveryForm()

    const selectedOptions = watch('gd_point_names')
    const dateFrom = watch('date_from')
    const dateTo = watch('date_to')
    const juridical_person_watch = watch('juridical_person')

    const juridicalPersonsList = useMemo(() => {
      return (
        juridicalPersonsData?.map((jur) => ({
          value: jur.jur_person_id,
          label: jur.juridical_person,
        })) || []
      )
    }, [juridicalPersonsData])

    const transformGDPointNames = useCallback(() => {
      return gd_point_names.map((pointName) => {
        const matchingItem = groupDeliveryData.find(
          (item) => item.label === pointName,
        )
        return {
          label: pointName,
          value: matchingItem ? matchingItem.value : pointName,
        }
      })
    }, [gd_point_names, groupDeliveryData])

    useEffect(() => {
      if (!isEdit)
        setIsOpenNotification({
          isOpen: false,
          message: '',
        })
      else checkPeriods()
    }, [isEdit, dateFrom, dateTo, groupDeliveryPeriods])

    useEffect(() => {
      if (gd_point_names && date_from) {
        const transformedGDPointNames = transformGDPointNames()

        const juridicalPerson = juridicalPersonsList.find((item) => {
          return item.label === juridical_person
        })

        reset({
          date_from: moment(date_from).format('MM.YYYY'),
          date_to: date_to ? moment(date_to).format('MM.YYYY') : null,
          gd_point_names: transformedGDPointNames,
          juridical_person: juridicalPerson ? juridicalPerson?.value : null,
        })
      }
    }, [
      date_from,
      date_to,
      transformGDPointNames,
      juridicalPersonsData,
      juridicalPersonsList,
    ])

    useEffect(() => {
      const selectedValues = new Set(selectedOptions?.map((opt) => opt.value))
      const availableOptions = groupDeliveryData.filter(
        (option) => !selectedValues.has(option.value),
      )
      setFilteredData(availableOptions)
    }, [groupDeliveryData, selectedOptions])

    useEffect(() => {
      setErrorPoints({ isError: false, gd_point_names: [] })
    }, [dateFrom, dateTo])

    useEffect(() => {
      const currentGdPointNames = watch('gd_point_names')
      if (errorPoints?.gd_point_names && currentGdPointNames) {
        const updatedGdPointNames = currentGdPointNames.map((item) => ({
          ...item,
          isErrored: errorPoints?.gd_point_names.includes(item.label),
        }))
        setValue('gd_point_names', updatedGdPointNames, {
          shouldValidate: true,
        })
      }
    }, [errorPoints?.gd_point_names, setValue, watch])

    useEffect(() => {
      if (errorPoints?.gd_point_names?.length > 0) handleSetError()
    }, [errorPoints?.gd_point_names])

    const handleSetError = debounce(() => {
      setError('gd_point_names', {
        type: 'custom',
        message: 'Проверьте ГТП',
      })
    }, 100)

    const checkPeriods = useCallback(() => {
      const newPeriod = {
        date_from: dateFrom
          ? moment(dateFrom, 'MM.YYYY').format('YYYY-MM')
          : null,
        date_to: dateTo ? moment(dateTo, 'MM.YYYY').format('YYYY-MM') : null,
      }

      if (newPeriod.date_from && newPeriod.date_to) {
        checkPeriodsOverlap(
          groupDeliveryPeriods,
          newPeriod,
          id,
          setIsOpenNotification,
        )
      }
    }, [dateFrom, dateTo, groupDeliveryPeriods, id, setIsOpenNotification])

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    const handleResetForm = useCallback(() => {
      reset()
    }, [reset])

    const onSubmit = ({
      date_from: dateFrom,
      date_to: dateTo,
      gd_point_names,
      juridical_person,
    }) => {
      if (isOpenNotification.isOpen) return

      const pointNamesArray = gd_point_names.map((point) => point.label)

      const formattedNewDateFrom = convertDateForAPI(dateFrom)
      const formattedNewDateTo = convertDateForAPI(dateTo)

      const juridicalPerson = juridicalPersonsList.find((item) => {
        return item.value === juridical_person
      })

      updateGroupDelivery({
        juridical_person: juridicalPerson
          ? juridicalPerson.label
          : juridical_person
          ? juridical_person
          : null,
        date_from: moment(date_from).format('yyyy-MM-DD'),
        date_to: date_to ? moment(date_to).format('yyyy-MM-DD') : null,
        new_date_from: formattedNewDateFrom,
        new_date_to: formattedNewDateTo,
        gd_point_names: pointNamesArray,
        gs_activity_areas_id,
        successAction: () => setIsEdit(false),
        errorAction(gd_point_names) {
          setErrorPoints({ isError: true, gd_point_names })
        },
      })
    }

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          bg="#fdfdfd"
          p="10px"
          variant="outline"
          boxShadow={'none'}
          m="15px"
        >
          <Flex position={'relative'} pt="20px">
            <PopoverMessage
              triggerElement={<Box position={'absolute'} w={'300px'} />}
              isOpen={isOpenNotification.isOpen}
              message={isOpenNotification.message}
              title="Проверьте выбранный период"
              onClick={() =>
                setIsOpenNotification({ isOpen: false, message: null })
              }
            />
            <Access
              permissions={[accessDict.update_read]}
              module={modulesDict.reference_books}
            >
              <Flex
                w="100%"
                justifyContent="flex-end"
                position={'absolute'}
                right={'0'}
                top={'-05px'}
                zIndex={1}
              >
                <EditButton
                  handleEditClick={handleEditClick}
                  isEdit={isEdit}
                  size={'xs'}
                />
              </Flex>
            </Access>

            <Box p={'10px'} w={'250px'}>
              <FormInputBlock
                valueWidth={'150px'}
                titleWidth={'80px'}
                allowEdit={true}
                title={'Дата с'}
                edit={isEdit}
                value={formatDateWithNominativeMonth(new Date(date_from))}
              >
                <DatePickerField
                  format="MM.yyyy"
                  showMonthYearPicker
                  name={'date_from'}
                  placeholder={'Дата от'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
              <Box m={'3px'} />

              <FormInputBlock
                titleWidth={'80px'}
                allowEdit={true}
                title={'Дата по'}
                edit={isEdit}
                value={
                  date_to
                    ? formatDateWithNominativeMonth(new Date(date_to))
                    : 'Текущий момент'
                }
              >
                <DatePickerField
                  format="MM.yyyy"
                  showMonthYearPicker
                  name={'date_to'}
                  placeholder={'Дата до'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Box p={'10px'} w={'600px'}>
              <Flex alignItems={'center'}>
                <FormInputBlock
                  titleWidth={'114px'}
                  allowEdit={true}
                  edit={true}
                  title={'ГТП:'}
                >
                  <SelectInputMultiForm
                    isCreatable
                    isReadOnly={!isEdit}
                    name={'gd_point_names'}
                    data={filteredData}
                    placeholder="Выберите ГТП"
                    isClearable
                    isMulti
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Flex>
              <Box m={'3px'} />
              <Flex alignItems={'center'}>
                <FormInputBlock
                  value={juridical_person}
                  titleWidth={'114px'}
                  allowEdit={true}
                  edit={isEdit}
                  title={'Юр. лицо:'}
                >
                  <SelectInputForm
                    placeholder="Юр. лицо:"
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="juridical_person"
                    data={juridicalPersonsList}
                    isClearable={true}
                    isCreatable={true}
                    notificationText="Новое юр. лицо будет создано"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Flex>
            </Box>
          </Flex>
          {isEdit && (
            <Flex p={'15px'}>
              <SubmitCancelButtons
                isDirty={isDirty}
                isUpdating={isUpdatingGroupDelivery}
                handleCancel={handleResetForm}
              />
              <Box ml={isDirty ? '10px' : '0'}>
                <DeleteGroupDelivery
                  gs_activity_areas_id={gs_activity_areas_id}
                  date_from={date_from}
                  date_to={date_to}
                  cancelEdit={() => setIsEdit(false)}
                />
              </Box>
            </Flex>
          )}
        </Card>
      </form>
    )
  },
)
