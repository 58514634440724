import React, { memo } from 'react'
import { TransServiceResponse } from 'shared/models'
import { TransServiceTable } from './TransServiceTable/TransServiceTable'
import { Box } from '@chakra-ui/react'

interface TransServiceListProps {
  data: TransServiceResponse[]
  loading: boolean
}

export const TransServiceList: React.FC<TransServiceListProps> = memo(
  ({ data, loading }): JSX.Element => {
    return (
      <Box mt="25px">
        <TransServiceTable tableData={data} loading={loading} />
      </Box>
    )
  },
)
