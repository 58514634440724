import React from 'react'
import { Box } from '@chakra-ui/react'

import { ListSvncemResponse } from 'shared/models'
import { SvncemTable } from './SvncemTable/SvncemTable'

export const SvncemList: React.FC<{
  data: ListSvncemResponse[]
  loading: boolean
}> = ({ data, loading }): JSX.Element => {
  return (
    <Box mt="25px">
      <SvncemTable tableData={data} loading={loading} />
    </Box>
  )
}
