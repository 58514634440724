import { useQuery } from 'react-query'

import { ListSvncemResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface SvncemData {
  value: string
  label: string
}

interface UseGetProductsResult {
  data: ListSvncemResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  svncemData: SvncemData[] | undefined
}

interface UseAllSvncem {
  enabled: boolean
}

export function useGetAllSvncem({
  enabled,
}: UseAllSvncem): UseGetProductsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    ListSvncemResponse[]
  >({
    queryKey: [queryKeys.priceCategories.svncem.allSvncem],
    queryFn: () =>
      PriceCategoriesService.getListSvncem().then((response) => response.data),
    enabled,
    retry: false,
  })

  const svncemData = data?.map((x) => ({
    value: x.svncem_id,
    label: `${x.base_element_name} `,
  }))

  return { data, isError, isFetching, isLoading, svncemData }
}
