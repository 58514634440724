import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react'

import {
  Box,
  Flex,
  IconButton,
  Input,
  ResponsiveValue,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'

import { format, isValid, parseISO } from 'date-fns'

import { normalizeValues, toasts, truncateString } from 'shared/utils'

// misc
import {
  HiDocumentDuplicate,
  HiOutlineDocumentDuplicate,
  HiXMark,
} from 'react-icons/hi2'
import { CloseIcon } from '@chakra-ui/icons'

interface OnUpdateProps {
  id: string
  field: string
  newValue: string
}
interface EditableCellProps {
  value: string
  row: {
    original: {
      isEdited: boolean
      edited: Record<string, boolean>
    }
  }
  column: () => void
  onUpdate: (OnUpdateProps) => void
  id: string
  field: string
  inputType?: string
  size?: string
  textAria?: boolean
  double?: boolean
  decimalPlaces?: number
}

interface BasicTableProps {
  children: React.ReactNode
  dateFormat?: string
  align?: ResponsiveValue<string>
  size?: string
}

interface BasicCommonColumnProps {
  children: string | React.ReactNode
  align?: 'center' | 'left' | 'right'
  size?: string
  double?: boolean
  maxChar?: number
}

interface HoursPeakColumnProps {
  children: string | React.ReactNode
  selected?: boolean
  editMode?: boolean
  emptyValue?: boolean
  isLoading: boolean
  identifier: string
  row?: any
  size?: string
  onUpdate?: ({
    id,
    newValue,
    index,
  }: {
    id: string
    newValue: boolean
    index: string
  }) => void
  index?: string
  value?: boolean
}

const stylesData = {
  fontSize: {
    sm: '10px',
    md: '12px',
    lg: '14px',
    xl: '16px',
  },
  cellHeight: {
    sm: '21px',
    md: '25px',
    lg: '27px',
  },
  inputHeight: {
    sm: '19px',
    md: '22px',
    lg: '25px',
  },
  double: {
    sm: '30px',
    md: '35px',
    lg: '40px',
  },
}

const MAX_CHARS_PER_LINE = 15

export const EditableCell: React.FC<EditableCellProps> = memo(
  ({
    value: initialValue,
    row,
    column,
    onUpdate,
    id,
    field,
    inputType,
    size = 'sm',
    textAria = false,
    double = false,
    decimalPlaces = 2,
  }): JSX.Element => {
    const regex = new RegExp(`^\\d+([\\.,]\\d{${decimalPlaces}})?$`)

    const initialNotificationState = {
      isOpen: false,
      message: '',
    }

    const [isOpenNotification, setIsOpenNotification] = useState<{
      isOpen: boolean
      message: string
    }>(initialNotificationState)
    const [value, setValue] = useState(initialValue)
    const [isEdited, setIsEdited] = useState<boolean>(false)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [coped, setCoped] = useState<boolean>(false)
    const [valid, setValid] = useState<boolean>(
      value ? regex.test(value) : true,
    )

    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const [rows, setRows] = useState(
      Math.max(1, Math.ceil(initialValue.length / MAX_CHARS_PER_LINE)),
    )

    useEffect(() => {
      if (isOpenNotification.isOpen) {
        setTimeout(() => {
          setIsOpenNotification({
            ...isOpenNotification,
            isOpen: false,
          })
        }, 3000)
      }
    }, [isOpenNotification.isOpen])

    useEffect(() => {
      if (coped) setTimeout(() => setCoped(false), 1500)
    }, [coped])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setValue(newValue)
      setIsEdited(true)
      onUpdate({ id, field, newValue })
    }

    const formatValue = (value: string): string => {
      if (!value) return ''

      const normalizedValue = value.replace(',', '.')
      const parsedValue = parseFloat(normalizedValue)
      if (isNaN(parsedValue)) return ''

      const [integerPart, fractionalPart = ''] = normalizedValue.split('.')

      if (fractionalPart.length === decimalPlaces)
        return value.replace('.', ',')

      if (fractionalPart.length < decimalPlaces)
        return parsedValue.toFixed(decimalPlaces).replace('.', ',')

      return value.replace('.', ',')
    }

    const onBlur = useCallback(() => {
      setIsFocused(false)

      const formattedValue = formatValue(value)
      setValue(formattedValue)

      if (value !== initialValue) setIsEdited(true)

      if (inputType === 'number' && formattedValue) {
        if (!regex.test(formattedValue)) {
          setValid(false)
          setIsOpenNotification({
            isOpen: true,
            message: `Допускается только число с точностью до ${decimalPlaces} знака после запятой`,
          })
        } else {
          setValid(true)
          setIsOpenNotification(initialNotificationState)
        }
      }
    }, [value, initialValue, inputType, regex, decimalPlaces])

    const onFocus = () => {
      setIsFocused(true)
      setShowTooltip(false)
    }

    const onClear = useCallback(() => {
      setValue('')
      onUpdate({ id, field, newValue: '' })
      setIsEdited(true)
      setRows(1)
      setValid(true)
      setIsOpenNotification({
        isOpen: false,
        message: null,
      })
    }, [onUpdate, id, field])

    const onCopy = async () => {
      try {
        await navigator.clipboard.writeText(value)
        toasts.info({
          title: 'Скопировано!',
          description: 'Значение успешно скопировано в буфер обмена.',
          duration: 1500,
        })
        setCoped(true)
      } catch (err) {
        toasts.error({
          title: 'Ошибка!',
          description: 'Не удалось скопировать значение.',
          duration: 3000,
        })
      }
    }

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (!textAria && e.key === 'Enter') {
        ;(e.target as HTMLInputElement | HTMLTextAreaElement).blur()
      }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        inputType === 'number'
          ? e.target.value.replace(/[^0-9.,]/g, '')
          : e.target.value
      setValue(newValue)
      setIsEdited(true)
      onUpdate({ id, field, newValue })
      setValid(true)
      setIsOpenNotification({
        isOpen: false,
        message: null,
      })

      const newRows = Math.ceil(newValue.length / MAX_CHARS_PER_LINE)
      setRows(Math.max(1, newRows))
    }

    const handleMouseEnter = () => {
      setIsHovered(true)
      if (!valid && !isFocused) {
        setShowTooltip(true)
      }
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
      setShowTooltip(false)
    }

    return (
      <Flex
        maxH={textAria ? '' : stylesData.cellHeight[size]}
        overflow={'visible'}
        alignItems={'center'}
        justifyContent={'center'}
        padding={double ? '3px 1px' : '1px'}
        position={'relative'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isFocused && value !== '' && (
          <Box
            position={'absolute'}
            zIndex={999}
            right={textAria ? '15px' : '5px'}
            cursor={'pointer'}
            _hover={{ color: '#104fec' }}
            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
              e.preventDefault()
              onClear()
            }}
          >
            <HiXMark />
          </Box>
        )}
        <>
          {textAria ? (
            <Textarea
              type="text"
              border={isEdited ? '1px solid #34ae6f' : '1px solid #d5dee7'}
              textAlign={'center'}
              m={0}
              minHeight={
                double ? stylesData.double[size] : stylesData.inputHeight[size]
              }
              height={double ? stylesData.double[size] : 'auto'}
              p={'0 20px'}
              size="xs"
              w={'100%'}
              fontSize={stylesData.fontSize[size]}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
              // @ts-ignore
              onChange={handleInput}
              className={isEdited ? 'cell-edited' : ''}
              // rows={rows}
              onKeyDown={handleKeyDown}
              rows={2}
              style={{ resize: 'none' }}
            />
          ) : (
            <Tooltip
              label={`Ошибка ввода: ${isOpenNotification.message}`}
              placement="top"
              isOpen={isOpenNotification.isOpen || showTooltip}
              bg="red.600"
              hasArrow
            >
              <Input
                isInvalid={!valid}
                type="text"
                border={isEdited ? '1px solid #34ae6f' : '1px solid #d5dee7'}
                textAlign={isFocused ? 'center' : 'right'}
                m={0}
                minHeight={
                  double
                    ? stylesData.double[size]
                    : stylesData.inputHeight[size]
                }
                height={double ? stylesData.double[size] : 'auto'}
                p={'0 5px'}
                // mr={'5px'}
                onKeyDown={handleKeyDown}
                size="xs"
                w={'100%'}
                fontSize={stylesData.fontSize[size]}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleInput}
                className={isEdited ? 'cell-edited' : ''}
              />
            </Tooltip>
          )}
        </>

        <Box
          zIndex={1}
          mr={'5px'}
          position={'absolute'}
          left={'5px'}
          opacity={value && isHovered ? 0.6 : 0}
          cursor={value ? 'pointer' : 'auto'}
          color={coped ? '#259e5f' : 'gray'}
          onClick={value ? () => onCopy() : null}
          transition={'opacity .2s ease'}
          _hover={value ? { opacity: 1 } : {}}
        >
          {coped ? <HiDocumentDuplicate /> : <HiOutlineDocumentDuplicate />}
        </Box>
      </Flex>
    )
  },
)

export const BasicTitle: React.FC<BasicTableProps> = ({
  children,
  size = 'sm',
}) => {
  return (
    <Box w={'100%'} textAlign={'center'} fontSize={stylesData.fontSize[size]}>
      {children}
    </Box>
  )
}

export const BasicSumColumn: React.FC<BasicTableProps> = memo(
  ({ children, align = 'right', size = 'sm' }) => {
    return (
      <Box
        w={'100%'}
        textAlign={align as ResponsiveValue<any>}
        fontSize={stylesData.fontSize[size]}
        color={children === '0.00' ? '#c0c0c0ff' : 'black'}
      >
        {normalizeValues(children, 'sum')}
      </Box>
    )
  },
)

export const BasicDateColumn: React.FC<BasicTableProps> = memo(
  ({ children, dateFormat = 'dd-MM-yyyy', size = 'sm' }) => {
    const date = parseISO(children as string)

    if (!date && !isValid(date)) return ''

    let formattedDate

    switch (dateFormat) {
      case 'dd-MM-yyyy':
        formattedDate = format(date, 'dd-MM-yyyy')
        break
      case 'date_month_string_with_year':
        formattedDate = normalizeValues(date, 'date_month_string_with_year')
        break
      default:
        formattedDate = ''
        break
    }

    return (
      <Box w={'100%'} textAlign={'center'} fontSize={stylesData.fontSize[size]}>
        {formattedDate}
      </Box>
    )
  },
)

export const HoursIntervalsPeakColumn: React.FC<HoursPeakColumnProps> = memo(
  ({
    children,
    selected,
    editMode,
    onUpdate,
    index,
    row,
    size,
    identifier,
    isLoading = false,
    value,
  }) => {
    const handleChange = () => {
      onUpdate({
        id: row[`${Number(index) + 1}${identifier}`],
        newValue: !value,
        index,
      })
    }

    return (
      <Flex
        pointerEvents={isLoading ? 'none' : 'auto'}
        opacity={isLoading ? 0.5 : 1}
        onClick={editMode ? () => handleChange() : null}
        alignItems={'center'}
        justifyContent={'center'}
        bg={value ? '#2c5282' : ''}
        border={'2px solid transparent'}
        color={value ? 'white' : 'black'}
        cursor={editMode ? 'pointer' : 'auto'}
        _hover={
          editMode
            ? {
                border: '2px solid #20b36e',
                color: value ? 'white' : 'black',
              }
            : {}
        }
        fontSize={stylesData.fontSize[size]}
        transition={'background-color .2s ease'}
      >
        {children}
      </Flex>
    )
  },
)

export const HoursPeakColumn: React.FC<HoursPeakColumnProps> = ({
  children,
  selected,
  editMode,
  onUpdate,
  index,
  row,
  size,
  identifier,
  isLoading = false,
  emptyValue = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<boolean>(selected)

  const handleChange = () => {
    setSelectedValue(selectedValue ? false : true)
    onUpdate({
      id: row[`${Number(index) + 1}${identifier}`],
      newValue: selectedValue ? false : true,
      index,
    })
  }

  return (
    <Flex
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={isLoading ? 0.5 : 1}
      onClick={editMode ? () => handleChange() : null}
      alignItems={'center'}
      justifyContent={'center'}
      bg={selectedValue ? '#2c5282' : ''}
      border={'2px solid transparent'}
      color={selectedValue ? 'white' : 'black'}
      cursor={editMode ? 'pointer' : 'auto'}
      _hover={
        editMode
          ? {
              border: '2px solid #20b36e',
              color: selectedValue ? 'white' : 'black',
            }
          : {}
      }
      fontSize={stylesData.fontSize[size]}
      transition={'background-color .2s ease'}
    >
      {children}
    </Flex>
  )
}

export const BasicCommonColumn: React.FC<BasicCommonColumnProps> = ({
  children,
  align = 'center',
  size = 'sm',
  double = false,
  maxChar = null,
}) => {
  const [coped, setCoped] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLDivElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(children as string)
      toasts.info({
        title: 'Скопировано!',
        description: 'Значение успешно скопировано в буфер обмена.',
        duration: 1500,
      })
      setCoped(true)
    } catch (err) {
      toasts.error({
        title: 'Ошибка!',
        description: 'Не удалось скопировать значение.',
        duration: 3000,
      })
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      btnRef.current &&
      !btnRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    )
      onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const container = document.querySelector('.table-container')
    if (!container) return

    const checkVisibility = () => {
      if (!btnRef.current) return
      const rect = btnRef.current.getBoundingClientRect()
      const contRect = container.getBoundingClientRect()

      if (rect.bottom < contRect.top || rect.top > contRect.bottom) onClose()
    }
    container.addEventListener('scroll', checkVisibility)
    return () => {
      container.removeEventListener('scroll', checkVisibility)
    }
  }, [isOpen])

  const truncatedContent = maxChar
    ? truncateString(children as string, maxChar)
    : children

  return (
    <Box position="relative" ref={btnRef}>
      <Tooltip
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          coped
            ? setTimeout(() => setIsHovered(false), 500)
            : setIsHovered(false)
        }}
        isOpen={isOpen}
        placement="top"
        label={
          <Flex pl={'20px'} alignItems={'center'}>
            <Box
              zIndex={1}
              mr={'5px'}
              position={'absolute'}
              left={'5px'}
              opacity={children && isHovered ? 0.6 : 0}
              cursor={children && maxChar ? 'pointer' : 'auto'}
              color={coped ? '#259e5f' : 'gray'}
              onClick={children ? () => onCopy() : null}
              transition={'opacity .2s ease'}
              _hover={children ? { opacity: 1 } : {}}
            >
              {coped ? <HiDocumentDuplicate /> : <HiOutlineDocumentDuplicate />}
            </Box>
            <Flex
              zIndex={10}
              rounded={'full'}
              bg="#e42f2f"
              fontSize="6px"
              position="absolute"
              right="-2"
              top="-2"
              w={'20px'}
              h={'20px'}
              alignItems={'center'}
              justifyContent={'center'}
              color="white"
              padding={'1px'}
              cursor={'pointer'}
              _hover={{ bg: '#d81e1e' }}
              transition={'background-color 0.2s'}
              onClick={onClose}
            >
              <CloseIcon />
            </Flex>
            {children}
          </Flex>
        }
        hasArrow
        closeOnClick={false}
        bg="white"
        color="black"
        ref={tooltipRef}
        pointerEvents="all"
        shadow={'lg'}
        borderRadius={'5px'}
      >
        <Flex
          w="100%"
          textAlign={align}
          opacity={children === '0' ? 0.4 : 1}
          height={double ? stylesData.double[size] : 'auto'}
          maxHeight={double ? stylesData.double[size] : 'auto'}
          p="0"
          justifyContent={align}
          alignItems="center"
          overflow="hidden"
          onClick={() =>
            maxChar &&
            typeof children === 'string' &&
            children.length > maxChar &&
            (isOpen ? null : onOpen())
          }
          cursor={
            maxChar && children && (children as string).length > maxChar
              ? 'pointer'
              : 'auto'
          }
        >
          {truncatedContent}
        </Flex>
      </Tooltip>
    </Box>
  )
}
