import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Path } from 'react-hook-form'

import {
  Center,
  Spinner,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Button,
  Collapse,
  Switch,
  Grid,
  Card,
} from '@chakra-ui/react'

import { Access, useAccess } from 'app/providers'
import { useGetCompanyDetail } from 'entities/companies'
import { FormInputControl, MaskedInput, TextTitle, Loader } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import { useUpdateCompany } from '../models/updateCompanyService'
import { EditCompanyFormData, useEditCompanyForm } from './EditCompanyForm'
import { formSections } from './data'

// misc

import { MdOutlineSave } from 'react-icons/md'

import {
  HiChevronUp,
  HiChevronDown,
  HiOutlineChevronDoubleUp,
  HiOutlineChevronDoubleDown,
} from 'react-icons/hi2'

interface EditCompanyProps {
  companyId: string
  isExpanded: boolean
}

type SectionId = 'legal_address' | 'mailing_address'

type AddressMatchState = {
  legal_address: boolean
  mailing_address: boolean
}

type OpenSectionsState = Record<string, boolean>

export const EditCompany: React.FC<EditCompanyProps> = React.memo((props) => {
  const { companyId, isExpanded } = props

  const [openSections, setOpenSections] = useState<OpenSectionsState>({})

  const [theSameAddresses, setTheSameAddresses] = useState<AddressMatchState>({
    legal_address: false,
    mailing_address: false,
  })

  const { hasAccess } = useAccess()

  const { isLoading, data } = useGetCompanyDetail({
    enabled: isExpanded && !!companyId,
    companyId,
  })

  const { mutate: updateCompany, isLoading: isLoadingUpdate } =
    useUpdateCompany()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    setValue,
    watchedFields,
  } = useEditCompanyForm()

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const updatedOpenSections = formSections.reduce((acc, section) => {
        const hasError = Object.keys(errors).includes(section.id)
        acc[section.id] = hasError
        return acc
      }, {})

      setOpenSections(updatedOpenSections)

      const firstErrorSectionId = formSections.find((section) =>
        Object.keys(errors).includes(section.id),
      )?.id
      if (
        firstErrorSectionId &&
        sectionRefs.current[firstErrorSectionId].current
      ) {
        sectionRefs.current[firstErrorSectionId].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }, [errors])

  const initialFormData = useMemo(() => {
    if (data) {
      return {
        general_info: {
          short_name: data.short_name,
          full_name: data.full_name,
          ogrn: data.ogrn,
          inn: data.inn,
          kpp: data.kpp,
        },
        bank_details: {
          account_number: data.account_number,
          bic: data.bic,
          bank_name: data.bank_name,
          correspondent_account: data.correspondent_account,
        },
        contacts: {
          email: data.email,
          phone_number: data.phone_number.replace('+', ''),
        },
        director: {
          director_position: data.director_position,
          director_last_name: data.director_last_name,
          director_first_name: data.director_first_name,
          director_middle_name: data.director_middle_name,
          basis_of_action: data.basis_of_action,
        },
        legal_address: data.legal_address,
        actual_address: data.actual_address,
        mailing_address: data.mailing_address,
      }
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setTheSameAddresses({
        legal_address: data.legal_address.is_same,
        mailing_address: data.mailing_address.is_same,
      })
      reset(initialFormData)
    }
  }, [data, reset])

  const toggleSection = useCallback(
    (sectionId: string) => {
      if (!theSameAddresses[sectionId as SectionId]) {
        setOpenSections((prev) => ({ ...prev, [sectionId]: !prev[sectionId] }))
      }
    },
    [theSameAddresses],
  )

  const closeAllSections = useCallback(() => {
    const closedSections = formSections.reduce((acc, section) => {
      acc[section.id] = false
      return acc
    }, {} as OpenSectionsState)
    setOpenSections(closedSections)
  }, [formSections])

  const openAllSections = useCallback(() => {
    const openedSections = formSections.reduce((acc, section) => {
      acc[section.id] = !theSameAddresses[section.id as keyof AddressMatchState]
      return acc
    }, {})
    setOpenSections(openedSections)
  }, [formSections, theSameAddresses])

  const handleSwitchChange = useCallback(
    (sectionId: SectionId, isChecked: boolean) => {
      setTheSameAddresses((prev) => ({
        ...prev,
        [sectionId]: isChecked,
      }))

      setOpenSections((prev) => ({
        ...prev,
        [sectionId]: !isChecked,
      }))

      const actualAddressFields = [
        'postal_code',
        'region',
        'district',
        'city',
        'street',
        'house_number',
        'block',
        'building',
        'apartment',
      ]

      if (isChecked)
        actualAddressFields.forEach((field) => {
          setValue(
            // @ts-ignore
            `${sectionId}.${field}`,
            control._formValues.actual_address[field],
            { shouldDirty: true },
          )
        })
      else setValue(`${sectionId}.is_same`, isChecked, { shouldDirty: true })
    },
    [setValue, control, setTheSameAddresses, setOpenSections],
  )

  const onSubmit = useCallback(
    ({
      general_info,
      bank_details,
      contacts,
      director,
      legal_address,
      actual_address,
      mailing_address,
    }) => {
      const updatedLegalAddress = {
        ...legal_address,
        is_same: theSameAddresses.legal_address,
      }
      const updatedMailingAddress = {
        ...mailing_address,
        is_same: theSameAddresses.mailing_address,
      }

      const allData = {
        director_position: director.director_position,
        director_last_name: director.director_last_name,
        director_first_name: director.director_first_name,
        basis_of_action: director.basis_of_action,
        legal_address: updatedLegalAddress,
        actual_address,
        mailing_address: updatedMailingAddress,
        short_name: general_info.short_name,
        full_name: general_info.full_name,
        inn: general_info.inn,
        kpp: general_info.kpp,
        ogrn: general_info.ogrn,
        account_number: bank_details.account_number,
        bic: bank_details.bic,
        bank_name: bank_details.bank_name,
        correspondent_account: bank_details.correspondent_account,
        email: contacts.email,
        phone_number: `+${contacts.phone_number}`,
      }

      updateCompany({
        companyId,
        companyName: data?.short_name,
        ...allData,
        successAction: () => null,
      })
    },
    [updateCompany, companyId, theSameAddresses],
  )

  const sectionRefs = useRef(
    formSections.reduce((acc, section) => {
      acc[section.id] = React.createRef()
      return acc
    }, {}),
  )

  const handleResetForm = useCallback(() => {
    reset()
    setTheSameAddresses({
      legal_address: data?.legal_address.is_same,
      mailing_address: data?.mailing_address.is_same,
    })
  }, [reset, data])

  if (isLoading)
    return (
      <Box m="150px 0">
        <Loader size="md" />
      </Box>
    )

  const accessAllowed: boolean = hasAccess({
    permissions: [accessDict.full_access, accessDict.update_read],
    module: modulesDict.companies,
  })

  const ActionsBlock: React.FC = () => {
    return (
      <Access
        permissions={[accessDict.full_access, accessDict.update_read]}
        module={modulesDict.companies}
      >
        {isDirty && (
          <Flex justifyContent={'flex-start'} mt="5px" mb="10px">
            <Button mr="10px" onClick={handleResetForm} size={'xs'}>
              Отмена
            </Button>
            <Button
              size={'xs'}
              mr="15px"
              type="submit"
              isLoading={isLoading || isLoadingUpdate}
              isDisabled={isLoading || isLoadingUpdate}
              bg="teal.400"
              color="white"
              display={'flex'}
              alignItems={'center'}
              _hover={{
                bg: 'teal.500',
              }}
            >
              Сохранить
              <Box fontSize={'18px'} ml={'10px'}>
                <MdOutlineSave />
              </Box>
            </Button>
          </Flex>
        )}
      </Access>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex mb={'8px'}>
        <Button onClick={openAllSections} size={'xs'} h={'20px'}>
          <HiOutlineChevronDoubleDown fontSize={'14px'} />
        </Button>
        <Button onClick={closeAllSections} size={'xs'} ml={'5px'} h={'20px'}>
          <HiOutlineChevronDoubleUp fontSize={'14px'} />
        </Button>
      </Flex>

      <ActionsBlock />
      {formSections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <Box ref={sectionRefs.current[section.id]} w="1000px">
            <Box onClick={() => toggleSection(section.id)}>
              <Flex
                alignItems={'center'}
                cursor={'pointer'}
                position={'relative'}
              >
                <TextTitle
                  size="small"
                  color={
                    Object.keys(errors).some((errorKey) =>
                      section.fields.some(
                        (field) => errorKey === field.name.split('.')[0],
                      ),
                    )
                      ? '#E53E3E'
                      : 'initial'
                  }
                >
                  {section.title}
                </TextTitle>
                <Box fontSize={'16px'} ml={'8px'} color="#00305d">
                  {openSections[section.id] ? (
                    <HiChevronUp />
                  ) : (
                    <HiChevronDown />
                  )}
                </Box>
                {['legal_address', 'mailing_address'].includes(section.id) && (
                  <Flex align="center" p="4" position={'absolute'} ml={'180px'}>
                    <FormLabel
                      fontSize={'14px'}
                      color={'gray.500'}
                      // fontWeight={'500'}
                      mb="0"
                    >
                      Совпадает с фактическим?
                    </FormLabel>
                    <Switch
                      size={'sm'}
                      id={`${section.id}-same-as`}
                      isChecked={theSameAddresses[section.id]}
                      isReadOnly={!accessAllowed}
                      onChange={(e) =>
                        handleSwitchChange(
                          section.id as SectionId,
                          e.target.checked,
                        )
                      }
                    />
                  </Flex>
                )}
              </Flex>
            </Box>

            <Card
              m={openSections[section.id] ? '10px 0' : null}
              p={openSections[section.id] ? '10px' : null}
              border={openSections[section.id] ? '1px solid transparent' : null}
              transition={'box-shadow 0.2s ease-in-out'}
              _hover={{
                border: '1px solid #11b8a284',
                shadow: 'md',
              }}
            >
              <Grid templateColumns="repeat(2, 1fr)" columnGap={'30px'}>
                {section.fields.map((field, fieldIndex) => {
                  const isError =
                    !!errors?.[field.name.split('.')[0]]?.[
                      field.name.split('.')[1]
                    ]
                  const errorMessage =
                    errors?.[field.name.split('.')[0]]?.[
                      field.name.split('.')[1]
                    ]?.message

                  return (
                    <Collapse
                      in={openSections[section.id]}
                      animateOpacity
                      key={fieldIndex}
                    >
                      <Flex alignItems={'center'} mt="10px" pl={'15px'}>
                        <Box w="200px" mr="15px">
                          <FormLabel
                            m="0"
                            fontSize={'14px'}
                            fontWeight={'500'}
                            color={'gray.500'}
                          >
                            {field.label}
                          </FormLabel>
                        </Box>
                        <Box w="300px">
                          <FormControl id={field.name} isInvalid={isError}>
                            {field.variant === 'masked' && (
                              <MaskedInput
                                control={control}
                                register={register}
                                errors={errors}
                                name={field.name as Path<EditCompanyFormData>}
                                mask={field.mask}
                                placeholder={field.placeholder}
                                watchedFields={watchedFields}
                                isRequired={field?.isRequired}
                                type={field.type}
                                size={'sm'}
                                smallErrorTextInside
                                onChangeFormatter={(inputValue: string) =>
                                  inputValue.replace(/\D/g, '')
                                }
                              />
                            )}

                            {field.variant === 'default' && (
                              <FormInputControl
                                name={field.name as Path<EditCompanyFormData>}
                                register={register}
                                errors={errors}
                                watchedFields={watchedFields}
                                isRequired={field?.isRequired}
                                placeholder={field.placeholder}
                                type={field.type}
                                size={'sm'}
                                // smallErrorTextInside
                              />
                            )}
                          </FormControl>
                        </Box>
                      </Flex>
                    </Collapse>
                  )
                })}
              </Grid>
            </Card>
          </Box>
          <Box borderTop={'1px solid #ededed'} mt="10px" mb="10px" />
        </React.Fragment>
      ))}
      <ActionsBlock />
    </form>
  )
})
