import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import { useOnClickOutside } from 'usehooks-ts'
import { Cascader } from 'rsuite'

import {
  Box,
  Flex,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  Kbd,
  MenuGroup,
  MenuDivider,
  Input,
  Tooltip,
} from '@chakra-ui/react'

// import { mockGroupedData } from '../data'

// misc
import {
  HiListBullet,
  HiMiniCog,
  HiMiniPencil,
  HiMiniPlusCircle,
  HiMiniXCircle,
  HiOutlineClipboardDocumentList,
  HiOutlinePlus,
  HiOutlinePlusCircle,
  HiOutlineSquaresPlus,
  HiPlusCircle,
} from 'react-icons/hi2'
import { ProductsThreeResponse } from 'shared/models'

export interface CascadePlannerNode {
  label: string
  value: string
  children?: CascadePlannerNode[]
}

export interface TreeNode {
  label: string
  value: string
  node_id: string
  node_name: string
  structure: string
  children?: TreeNode[]
}

interface CascadePlannerProps {
  threeData: ProductsThreeResponse[]
}

enum ElementTypes {
  input,
  builder,
}

interface ElementsState {
  type: ElementTypes
  value: any
  elementId: string
  editable: boolean
  autoFocus: boolean
}

interface AddElementMenuProps {
  onAddElement: (ElementTypes) => void
}

interface InputBuilderProps {
  editable: boolean
  elementId: string
  onBlur: (value: string) => void
  handleChangeEditable: (id: string, editable: boolean) => void
  onDelete: () => void
  transformedData: TreeNode[]
  initialValue?: string
  autoFocus?: boolean
  elementIndex?: string
}

interface BuilderProps {
  editable: boolean
  elementId: string
  initialValue?: any
  transformedData: TreeNode[]
  handleChangeEditable: (id: string, editable: boolean) => void
  onDelete: () => void
  elementIndex?: string
}

interface CustomToggleProps {
  onClick?: () => void
  selectedNames: string[]
  multiSelectedValues: string[]
  elementIndex?: string
}

interface ElementProps {
  children?: React.ReactNode
  color: string
  isFirst?: boolean
}

function transformTree(data: ProductsThreeResponse[]): TreeNode[] {
  return data.map((item) => ({
    label: item.node_name,
    value: item.node_name.toLowerCase(),
    node_id: item.node_id,
    node_name: item.node_name,
    structure: item.structure,
    ...(item.child ? { children: transformTree(item.child) } : {}),
  }))
}

const lightenColor = (color: string, percent: number): string => {
  let num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

const AddElementMenu: React.FC<AddElementMenuProps> = memo(
  ({ onAddElement }): JSX.Element => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          as={Button}
          cursor={'pointer'}
          colorScheme={'blue'}
          bg="blue.700"
          _hover={{ bg: 'blue.600' }}
        >
          <Box fontSize={'15px'} color="white">
            <HiMiniPlusCircle />
          </Box>
        </MenuButton>
        <MenuList>
          <MenuGroup title="Добавить">
            <MenuDivider />
            <MenuItem
              onClick={() => onAddElement(ElementTypes.builder)}
              fontSize={'14px'}
              icon={
                <Box fontSize={'20px'}>
                  <HiMiniCog />
                </Box>
              }
            >
              Элемент
            </MenuItem>
            <MenuItem
              onClick={() => onAddElement(ElementTypes.input)}
              fontSize={'14px'}
              icon={
                <Box fontSize={'20px'}>
                  <HiMiniPencil />
                </Box>
              }
            >
              Строку
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    )
  },
)

const CustomToggle: React.FC<CustomToggleProps> = ({
  onClick,
  selectedNames,
  elementIndex,
  multiSelectedValues,
}) => {
  const colorDate = {
    0: '#58b57b',
    1: '#adadad',
    2: '#4e86ff',
    3: '#ff9c23',
    4: '#3ca6bd',
  }

  return (
    <Box cursor="pointer" onClick={onClick}>
      <Flex align={'center'}>
        {selectedNames.length > 0 ? (
          <>
            {selectedNames.map((el, index) => {
              // const color = lightenColor(baseColor, index * 7)
              const color = lightenColor(
                colorDate[Number(elementIndex)],
                index * 7,
              )
              return (
                <Element key={index} color={color} isFirst={index === 0}>
                  {el}
                </Element>
              )
            })}
            {multiSelectedValues.length > 0 && (
              <Flex
                // ml="15px"

                borderRadius={'5px'}
                p={'0x 10px'}
                // bg={'#ffffe9'}
                bg={'#ffffe9'}
                ml="-50px"
                pl="50px"
                // border={'1px solid #13dfa5'}
              >
                {multiSelectedValues.map((el, index) => {
                  return (
                    <Flex
                      justifyContent={'center'}
                      alignItems={'center'}
                      // key={index}
                      bg={'#ffffe9'}
                      // border={'1px solid #dbdbdb'}
                      border={'1px solid #dbdbdb'}
                      p="2px 15px"
                      _last={{
                        mr: '0',
                      }}
                      fontSize={'14px'}
                      _hover={{ bg: '#ffffde' }}
                    >
                      {el}
                    </Flex>
                  )
                })}
              </Flex>
            )}
          </>
        ) : (
          <Element color={'#e3e3e3'} isFirst={true}>
            {'Выберете элемент'}
          </Element>
        )}
      </Flex>
    </Box>
  )
}

const Element: React.FC<ElementProps> = ({
  children,
  color,
  isFirst = false,
}): JSX.Element => {
  return (
    <Box
      fontSize={'14px'}
      position="relative"
      w="fit-content"
      bg={color}
      p="3px 15px"
      mr="-10px"
      _last={{
        mr: '0',
      }}
      clipPath={
        isFirst
          ? 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)'
          : 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)'
      }
    >
      {children}
    </Box>
  )
}

const Builder: React.FC<BuilderProps> = memo(
  ({
    editable,
    elementId,
    initialValue,
    handleChangeEditable,
    onDelete,
    elementIndex,
    transformedData,
  }): JSX.Element => {
    const [value, setValue] = useState<string | null>(null)
    const [selectedNames, setSelectedNames] = useState<string[]>([])
    const [multiSelectedNames, setMultiSelectedNames] = useState<string[]>([])
    const [multi, setMulti] = useState<boolean>(false)
    const [multiSelectedValues, setMultiSelectedValues] = useState<string[]>([])
    const [isDeleteHover, setIsDeleteHover] = useState<boolean>(false)
    const [isBlockHover, setIsBlockHover] = useState<boolean>(false)

    console.log('multiSelectedValues ===>', multiSelectedValues)
    console.log('selectedNames ===>', selectedNames)

    useEffect(() => {
      if (!multi) setMultiSelectedValues([])
    }, [multi])

    const handleSelect = (node: any, selectedPaths: any) => {
      if (selectedPaths) {
        const names = selectedPaths.map((path: any) => path.label)
        setSelectedNames(names)
      }
    }

    const handleMultiSelect = (values: string[]) => {
      setMultiSelectedValues(values)

      const findLabels = (data, selectedValues) => {
        const result = []
        data.forEach((item) => {
          if (selectedValues.includes(item.value)) result.push(item.label)
          if (item.children)
            result.push(...findLabels(item.children, selectedValues))
        })
        return result
      }

      const names = findLabels(transformedData, values)
      setMultiSelectedNames(names)
    }

    const handleClick = () => {
      if (!editable) handleChangeEditable(elementId, true)
    }

    const renderMenu = (node: React.ReactNode, column: any) => {
      const isLastColumn =
        column.items &&
        column.items.every(
          (item: any) => !item.children || item.children.length === 0,
        )

      return (
        <div>
          {isLastColumn && (
            <Box
              borderBottom="1px solid #ccc"
              bg={multi ? 'teal.400' : 'blue.700'}
              color={'white'}
            >
              <Menu>
                <MenuButton
                  p="5px"
                  h={'28px'}
                  as={Flex}
                  display={'flex'}
                  alignItems={'center'}
                  onClick={() => setMulti(!multi)}
                  cursor={'pointer'}
                >
                  <Flex alignItems={'center'} minW={'250px'}>
                    <Box mr="5px" fontSize={'18px'}>
                      {multi ? <HiOutlinePlus /> : <HiOutlineSquaresPlus />}
                    </Box>
                    {multi
                      ? 'Выбрать один элемент'
                      : 'Выбрать несколько опциональными'}
                  </Flex>
                </MenuButton>

                {multi && (
                  <MenuOptionGroup
                    type="checkbox"
                    value={multiSelectedValues}
                    onChange={(values) => handleMultiSelect(values as string[])}
                    h={'26px'}
                  >
                    {column?.items?.map((item) => (
                      <MenuItemOption
                        h={'26px'}
                        borderTop={'1px solid #e3e3e3'}
                        key={item.value}
                        value={item.value}
                        color={
                          multiSelectedValues.includes(item.value)
                            ? 'black'
                            : 'black'
                        }
                        bg={
                          multiSelectedValues.includes(item.value)
                            ? '#ffffe9'
                            : 'white'
                        }
                      >
                        {item.label} (Опционально)
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}
              </Menu>
            </Box>
          )}
          {isLastColumn ? <>{!multi ? node : null}</> : <>{node}</>}
        </div>
      )
    }

    return (
      <Box
        border={'1px solid transparent'}
        p="3px 5px"
        borderRadius={'5px'}
        cursor={'pointer'}
        role="group"
        transition={'border 0.2s, box-shadow 0.2s'}
        onClick={() => handleClick()}
        _hover={{
          border: '1px solid',
          borderColor: isDeleteHover ? 'red.500' : '#3182ce',
          boxShadow: isDeleteHover ? '0 0 0 1px red' : '0 0 0 1px #3182ce',
        }}
      >
        <Box
          position="absolute"
          top={'-22px'}
          right={'-5px'}
          visibility="hidden"
          opacity={0}
          transition="visibility 0.2s, opacity 0.2s"
          _groupHover={{
            visibility: 'visible',
            opacity: 1,
          }}
        >
          <Flex>
            <Box
              p="1px 5px"
              pl="15px"
              _hover={{}}
              onMouseEnter={() => setIsDeleteHover(true)}
              onMouseLeave={() => setIsDeleteHover(false)}
              onClick={onDelete}
            >
              <Box
                color={'red.500'}
                _hover={{ color: 'red.600' }}
                fontSize={'18px'}
              >
                <HiMiniXCircle />
              </Box>
            </Box>
          </Flex>
        </Box>
        <Cascader
          value={value}
          onChange={setValue}
          onSelect={(node, selectedPaths) => handleSelect(node, selectedPaths)}
          data={transformedData}
          style={{ width: 'fit-content' }}
          searchable={false}
          renderColumn={(node, column) => renderMenu(node, column)}
          toggleAs={() => (
            <CustomToggle
              selectedNames={selectedNames}
              multiSelectedValues={multiSelectedValues}
              elementIndex={elementIndex}
            />
          )}
          popupClassName={'custom-dropdown'}
        />
      </Box>
    )
  },
)

const InputBuilder: React.FC<InputBuilderProps> = memo(
  ({
    editable,
    elementId,
    initialValue,
    onBlur,
    autoFocus,
    handleChangeEditable,
    onDelete,
  }): JSX.Element => {
    const [value, setValue] = useState<string>('')
    const [isDeleteHover, setIsDeleteHover] = useState<boolean>(false)
    const [isBlockHover, setIsBlockHover] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const ref = useRef(null)

    useOnClickOutside(ref, () => {
      const trimmedValue = value.trim()
      onBlur(trimmedValue)
    })
    useEffect(() => {
      if (initialValue) setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      if (autoFocus && ref.current) ref.current.focus()
    }, [autoFocus])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const input: string = e.target.value
      const filteredInput = input.replace(/[^()\.,+\-*/]/g, '')

      setIsError(input !== filteredInput)
      setValue(filteredInput)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const trimmedValue = value.trim()
        onBlur(trimmedValue)
      }
    }

    const handleClick = () => {
      if (!editable) handleChangeEditable(elementId, true)
    }

    return (
      <Box>
        {editable ? (
          <Tooltip
            label="Разрешены только символы: ( ) . , + - * /"
            isOpen={isError}
            hasArrow
            bg="red.600"
            color="white"
            placement="top"
            closeDelay={3000}
          >
            <Input
              m={'3px 0'}
              fontWeight={'bold'}
              fontSize={'16px'}
              w={'80px'}
              ref={ref}
              size="xs"
              value={value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              borderColor={isError ? 'red.500' : 'gray.200'}
              focusBorderColor={isError ? 'red.500' : 'blue.500'}
            />
          </Tooltip>
        ) : (
          <Box>
            <Flex
              position={'relative'}
              alignItems={'center'}
              // onMouseEnter={() => setIsBlockHover(true)}
              onMouseLeave={() => setIsBlockHover(false)}
            >
              <Box
                position={'relative'}
                border={'1px solid transparent'}
                p="3px 5px"
                borderRadius={'5px'}
                cursor={'pointer'}
                role="group"
                transition={'border 0.2s, box-shadow 0.2s'}
                onClick={() => handleClick()}
                _hover={{
                  border: '1px solid',
                  borderColor: isDeleteHover ? 'red.500' : '#3182ce',
                  boxShadow: isDeleteHover
                    ? '0 0 0 1px red'
                    : '0 0 0 1px #3182ce',
                }}
              >
                <Box
                  position="absolute"
                  top={'-22px'}
                  right={'-5px'}
                  visibility="hidden"
                  opacity={0}
                  transition="visibility 0.2s, opacity 0.2s"
                  _groupHover={{
                    visibility: 'visible',
                    opacity: 1,
                  }}
                >
                  <Flex>
                    <Box
                      p="1px 5px"
                      pl="15px"
                      _hover={{}}
                      onMouseEnter={() => setIsDeleteHover(true)}
                      onMouseLeave={() => setIsDeleteHover(false)}
                      onClick={onDelete}
                    >
                      <Box
                        color={'red.500'}
                        _hover={{ color: 'red.600' }}
                        fontSize={'18px'}
                      >
                        <HiMiniXCircle />
                      </Box>
                    </Box>
                  </Flex>
                </Box>

                {value
                  .replace(/\s/g, '')
                  .split('')
                  .map((char, index) => (
                    <Kbd key={index} fontSize="18px" m="0 3px">
                      {char}
                    </Kbd>
                  ))}
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    )
  },
)

export const CascadePlannerDragAndDrop: React.FC<CascadePlannerProps> = memo(
  ({ threeData }): JSX.Element => {
    const [elements, setElements] = useState<ElementsState[]>([])

    const transformedData: TreeNode[] = useMemo(
      () => transformTree(threeData || []),
      [threeData],
    )

    const elementsData = {
      [ElementTypes.input]: InputBuilder,
      [ElementTypes.builder]: Builder,
    }

    const handleAddNewElement = useCallback((addedType: ElementTypes) => {
      setElements((prev: ElementsState[]) => [
        ...prev,
        {
          type: addedType,
          value: '',
          elementId: uuidv4(),
          editable: true,
          autoFocus: true,
        },
      ])
    }, [])

    const handleElementBlur = useCallback((id: string, value: string) => {
      setElements((prev: ElementsState[]) =>
        value === ''
          ? prev.filter((el) => el.elementId !== id)
          : prev.map((el) =>
              el.elementId === id
                ? { ...el, value, editable: false, autoFocus: false }
                : el,
            ),
      )
    }, [])

    const handleDeleteElement = useCallback((id: string) => {
      setElements((prev: ElementsState[]) =>
        prev.filter((el) => el.elementId !== id),
      )
    }, [])

    const handleChangeEditable = useCallback((id: string, edit: boolean) => {
      setElements((prev: ElementsState[]) =>
        prev.map((el) =>
          el.elementId === id ? { ...el, editable: edit, autoFocus: edit } : el,
        ),
      )
    }, [])

    const onDragEnd = (result: any) => {
      const { source, destination } = result

      if (!destination) return

      const reorderedElements = Array.from(elements)
      const [removed] = reorderedElements.splice(source.index, 1)
      reorderedElements.splice(destination.index, 0, removed)

      setElements(reorderedElements)
    }

    return (
      <Box border="2px solid #f0f0f0" p="10px 10px" borderRadius="8px">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-elements" direction="horizontal">
            {(provided) => (
              <Flex
                alignItems={'center'}
                gap={'5px'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {elements.map((element: ElementsState, index) => {
                  const Component = elementsData[element.type]

                  return (
                    <Draggable
                      key={element.elementId}
                      draggableId={element.elementId}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          position="relative"
                          display="flex"
                          alignItems="center"
                        >
                          <Component
                            {...element}
                            transformedData={transformedData}
                            onBlur={(value: string) =>
                              handleElementBlur(element.elementId, value)
                            }
                            elementIndex={String(index)}
                            handleChangeEditable={handleChangeEditable}
                            onDelete={() =>
                              handleDeleteElement(element.elementId)
                            }
                          />
                        </Box>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                <AddElementMenu onAddElement={handleAddNewElement} />
                <Button
                  isDisabled={elements.length === 0}
                  m="3px 0"
                  size={'xs'}
                  colorScheme="red"
                  ml={'auto'}
                  onClick={() => setElements([])}
                >
                  Очистить
                </Button>
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    )
  },
)
