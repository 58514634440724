import { Box } from '@chakra-ui/react'
import _ from 'lodash'

import { mockGroupedData } from './data'
import { FormulaBuilder } from './FormulaBuilder'
import { CascadePlanner } from './FormulaBuilder/CascadePlanner'
import { CascadePlannerDragAndDrop } from './FormulaBuilder/CascadePlannerDragAndDrop'
import { useGetProductsThreeService } from 'entities/prices'

export const TestPage2 = () => {
  const { data } = useGetProductsThreeService({ enabled: true })

  return (
    <div>
      <h1>TestPage2</h1>
      <Box m={'5'}>{/* <CascadePlanner data={mockGroupedData} /> */}</Box>
      <br />
      <br />
      <Box m={'5'}>
        <CascadePlannerDragAndDrop threeData={data || []} />
      </Box>

      <br />
      <br />
      <FormulaBuilder />
    </div>
  )
}
// #4da06d
