import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { UsersTariffResponse } from 'shared/models'
import { UsersTariffsTable } from './UsersTariffsTable/UsersTariffsTable'

interface UsersTariffsListProps {
  data: UsersTariffResponse[]
  loading: boolean
}

export const UsersTariffsList: React.FC<UsersTariffsListProps> = memo(
  ({ data, loading }): JSX.Element => {
    return (
      <Box mt="25px" maxW={'600px'}>
        <UsersTariffsTable tableData={data} loading={loading} />
      </Box>
    )
  },
)
