import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { OthersResponse } from 'shared/models'
import { OthersTable } from './OthersTable/OthersTable'

interface OthersListListProps {
  data: OthersResponse[]
  loading: boolean
}

export const OthersList: React.FC<OthersListListProps> = memo(
  ({ data, loading }): JSX.Element => {
    return (
      <Box mt="25px" maxW={'1000px'}>
        <OthersTable tableData={data} loading={loading} />
      </Box>
    )
  },
)
