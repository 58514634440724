import React, { memo } from 'react'
import { ListSalesAllowanceResponse } from 'shared/models'
import { SalesAllowanceTable } from './SalesAllowanceTable/SalesAllowanceTable'
import { Box } from '@chakra-ui/react'

interface SalesAllowanceListProps {
  data: ListSalesAllowanceResponse[]
  loading: boolean
}

export const SalesAllowanceList: React.FC<SalesAllowanceListProps> = memo(
  ({ data, loading }): JSX.Element => {
    return (
      <Box mt="25px">
        <SalesAllowanceTable tableData={data} loading={loading} />
      </Box>
    )
  },
)
