import {
  Box,
  Flex,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  Kbd,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { useState } from 'react'
import { ButtonToolbar, Cascader } from 'rsuite'

import { mockGroupedData } from '../data'

import {
  HiListBullet,
  HiMiniCog,
  HiMiniPencil,
  HiMiniPlusCircle,
  HiMiniXCircle,
  HiOutlineClipboardDocumentList,
  HiOutlinePlus,
  HiOutlinePlusCircle,
  HiOutlineSquaresPlus,
  HiPlusCircle,
} from 'react-icons/hi2'

import { AddIcon, ExternalLinkIcon } from '@chakra-ui/icons'

interface FormulaBuilderProps {}

interface CustomToggleProps {
  onClick?: () => void
  selectedNames: string[]
}

interface ElementProps {
  children?: React.ReactNode
  color: string
  isFirst?: boolean
}

const lightenColor = (color: string, percent: number): string => {
  let num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

const CustomToggle: React.FC<CustomToggleProps> = ({
  onClick,
  selectedNames,
}) => {
  return (
    <Box
      as="div"
      p="10px"
      bg="gray.200"
      borderRadius="5px"
      cursor="pointer"
      onClick={onClick}
      _hover={{ bg: 'gray.300' }}
    >
      {selectedNames.length > 0 ? selectedNames.join(', ') : 'Select an item'}
    </Box>
  )
}

const Element: React.FC<ElementProps> = ({
  children,
  color,
  isFirst = false,
}): JSX.Element => {
  return (
    <Box
      fontSize={'14px'}
      position="relative"
      w="fit-content"
      bg={color}
      p="3px 15px"
      mr="-10px" // Negative margin to overlap the arrows
      _last={{
        mr: '0', // No overlap for the last element
      }}
      clipPath={
        isFirst
          ? 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)'
          : 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)'
      } // Изменяем форму для первого элемента
      // _hover={{ bg: 'green.300' }}
    >
      {children}
    </Box>
  )
}

export const FormulaBuilder: React.FC<
  FormulaBuilderProps
> = (): JSX.Element => {
  const [value, setValue] = useState<string | null>(null) // Текущее значение
  const [selectedNames, setSelectedNames] = useState<string[]>([]) // Храним выбранные имена в обычном режиме
  const [multiSelectedNames, setMultiSelectedNames] = useState<string[]>([]) // Храним выбранные имена в режиме multi
  const [multi, setMulti] = useState<boolean>(false) // Режим выбора нескольких элементов
  const [multiSelectedValues, setMultiSelectedValues] = useState<string[]>([]) // Храним выбранные значения в режиме multi
  const [isDeleteHover, setIsDeleteHover] = useState<boolean>(false)
  const [isBlockHover, setIsBlockHover] = useState<boolean>(false)

  const baseColor = '#58b57b'
  const elementsCount = 5

  const handleSelect = (node: any, selectedPaths: any) => {
    if (selectedPaths) {
      const names = selectedPaths.map((path: any) => path.label)
      setSelectedNames(names)
    }
  }

  const handleMultiSelect = (values: string[]) => {
    setMultiSelectedValues(values)

    const findLabels = (data, selectedValues) => {
      const result = []
      data.forEach((item) => {
        if (selectedValues.includes(item.value)) result.push(item.label)
        if (item.children)
          result.push(...findLabels(item.children, selectedValues))
      })
      return result
    }

    const names = findLabels(mockGroupedData, values)
    setMultiSelectedNames(names)
  }

  const renderMenu = (node: React.ReactNode, column: any) => {
    const isLastColumn =
      column.items &&
      column.items.every(
        (item: any) => !item.children || item.children.length === 0,
      )

    return (
      <div>
        {isLastColumn && (
          <Box
            borderBottom="1px solid #ccc"
            bg={multi ? 'teal.400' : 'blue.700'}
            color={'white'}
          >
            <Menu>
              <MenuButton
                p="5px"
                h={'28px'}
                as={Flex}
                display={'flex'}
                alignItems={'center'}
                onClick={() => setMulti(!multi)}
                cursor={'pointer'}
              >
                <Flex alignItems={'center'} minW={'250px'}>
                  <Box mr="5px" fontSize={'18px'}>
                    {multi ? <HiOutlinePlus /> : <HiOutlineSquaresPlus />}
                  </Box>
                  {multi
                    ? 'Выбрать один элемент'
                    : 'Выбрать несколько опциональными'}
                </Flex>
              </MenuButton>

              {multi && (
                <MenuOptionGroup
                  type="checkbox"
                  value={multiSelectedValues}
                  onChange={(values) => handleMultiSelect(values as string[])}
                  h={'26px'}
                >
                  {column?.items?.map((item) => (
                    <MenuItemOption
                      h={'26px'}
                      borderTop={'1px solid #e3e3e3'}
                      key={item.value}
                      value={item.value}
                      color={
                        multiSelectedValues.includes(item.value)
                          ? 'black'
                          : 'black'
                      }
                      bg={
                        multiSelectedValues.includes(item.value)
                          ? '#ffffe9'
                          : 'white'
                      }
                    >
                      {item.label} (Опционально)
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              )}
            </Menu>
          </Box>
        )}
        {isLastColumn ? <>{!multi ? node : null}</> : <>{node}</>}
      </div>
    )
  }

  return (
    <Box>
      FormulaBuilder
      <Box>
        <Text fontWeight="bold">Выбранные в обычном режиме:</Text>
        <Flex alignItems={'center'}>
          <Box
            cursor={'pointer'}
            border={'1px solid transparent'}
            _hover={{
              border: '1px solid #3182ce',
              boxShadow: '0 0 0 1px #3182ce',
            }}
            p="3px 5px"
            borderRadius={'5px'}
          >
            <Flex align="center">
              {selectedNames.map((name, index) => {
                const color = lightenColor(baseColor, index * 7)
                return (
                  <Element key={index} color={color} isFirst={index === 0}>
                    {name}
                  </Element>
                )
              })}
            </Flex>
          </Box>

          <Flex h={'35px'} alignItems={'center'}>
            <Flex
              position={'relative'}
              alignItems={'center'}
              onMouseEnter={() => setIsBlockHover(true)}
              onMouseLeave={() => setIsBlockHover(false)}
            >
              <Box
                position={'relative'}
                border={'1px solid transparent'}
                p="3px 5px"
                borderRadius={'5px'}
                cursor={'pointer'}
                role="group"
                transition={'border 0.2s, box-shadow 0.2s'}
                _hover={{
                  border: '1px solid',
                  borderColor: isDeleteHover ? 'red.500' : '#3182ce',
                  boxShadow: isDeleteHover
                    ? '0 0 0 1px red'
                    : '0 0 0 1px #3182ce',
                }}
              >
                <Box
                  position="absolute"
                  top={'-22px'}
                  right={'-5px'}
                  visibility="hidden"
                  opacity={0}
                  transition="visibility 0.2s, opacity 0.2s"
                  _groupHover={{
                    visibility: 'visible',
                    opacity: 1,
                  }}
                >
                  {/* <Flex>
                    <Box
                      p="2px"
                      bg="white"
                      border={'1px solid #ededed'}
                      borderRadius={'5px'}
                      borderRightRadius={'none'}
                      borderRight={'none'}
                      _hover={{
                        bg: 'gray.50',
                        border: '1px solid #dbdbdb',
                        borderRightRadius: 'none',
                        borderRight: 'none',
                      }}
                      onMouseEnter={() => setIsDeleteHover(true)}
                      onMouseLeave={() => setIsDeleteHover(false)}
                    >
                      <Box color={'red.500'}>
                        <HiMiniXCircle />
                      </Box>
                    </Box>
                    <Box
                      p="2px"
                      bg="white"
                      border={'1px solid #ededed'}
                      borderRadius={'5px'}
                      borderLeftRadius={'none'}
                      _hover={{ bg: 'gray.100', border: '1px solid #dbdbdb' }}
                    >
                      <Box color={'green.500'}>
                        <HiMiniPlusCircle />
                      </Box>
                    </Box>
                  </Flex> */}
                  <Flex>
                    <Box
                      p="1px 5px"
                      pl="15px"
                      // bg="white"
                      // border={'1px solid #ededed'}
                      // borderRadius={'5px'}
                      // borderRightRadius={'none'}
                      // borderRight={'none'}
                      _hover={
                        {
                          // bg: 'gray.50',
                          // border: '1px solid #dbdbdb',
                          // borderRightRadius: 'none',
                          // borderRight: 'none',
                        }
                      }
                      onMouseEnter={() => setIsDeleteHover(true)}
                      onMouseLeave={() => setIsDeleteHover(false)}
                    >
                      <Box
                        color={'red.500'}
                        _hover={{ color: 'red.600' }}
                        fontSize={'18px'}
                      >
                        <HiMiniXCircle />
                      </Box>
                    </Box>
                    {/* <Box
                      p="2px"
                      bg="white"
                      border={'1px solid #ededed'}
                      borderRadius={'5px'}
                      borderLeftRadius={'none'}
                      _hover={{ bg: 'gray.100', border: '1px solid #dbdbdb' }}
                    >
                      <Box color={'green.500'}>
                        <HiMiniPlusCircle />
                      </Box>
                    </Box> */}
                  </Flex>
                </Box>

                {/* Основной контент */}
                <Kbd fontSize={'18px'} m={'0 3px'}>
                  +
                </Kbd>
                <Kbd fontSize={'18px'} m={'0 3px'}>
                  (
                </Kbd>
              </Box>
              {/* {isBlockHover && (
                <Flex
                  transition={'opacity 0.2s'}
                  position={'absolute'}
                  right={'-25px'}
                  cursor={'pointer'}
                  color="green.500"
                  alignItems={'center'}
                  opacity={'0.5'}
                  _hover={{ opacity: 1 }}
                  p={'0 3px'}
                >
                  <Box fontSize={'20px'}>
                    <HiMiniPlusCircle />
                  </Box>
                </Flex>
              )} */}
            </Flex>

            <Box
              cursor={'pointer'}
              border={'1px solid transparent'}
              // _hover={{ border: '1px solid #ededed' }}
              _hover={{
                border: '1px solid #3182ce',
                boxShadow: '0 0 0 1px #3182ce',
              }}
              p="3px 5px"
              borderRadius={'5px'}
            >
              <Flex align="center">
                {selectedNames.map((name, index) => {
                  const color = lightenColor(baseColor, index * 7)
                  return (
                    <Element key={index} color={color} isFirst={index === 0}>
                      {name}
                    </Element>
                  )
                })}
              </Flex>
            </Box>
            <Kbd fontSize={'18px'} m={'0 3px'}>
              )
            </Kbd>
          </Flex>

          <Menu>
            <MenuButton
              size="xs"
              as={Button}
              cursor={'pointer'}
              colorScheme={'blue'}
              bg="blue.700"
              _hover={{ bg: 'blue.600' }}
            >
              <Box fontSize={'15px'} color="white">
                <HiMiniPlusCircle />
              </Box>
            </MenuButton>
            <MenuList>
              <MenuGroup title="Добавить">
                <MenuDivider />
                <MenuItem
                  fontSize={'14px'}
                  icon={
                    <Box fontSize={'20px'}>
                      <HiMiniCog />
                    </Box>
                  }
                >
                  Элемент
                </MenuItem>
                <MenuItem
                  fontSize={'14px'}
                  icon={
                    <Box fontSize={'20px'}>
                      <HiMiniPencil />
                    </Box>
                  }
                >
                  Строку
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Flex>

        {/* <Text fontWeight="bold">Выбранные в режиме multi:</Text>
        <Flex m="5" align="center" cursor={'pointer'}>
          {multiSelectedNames.map((name, index) => {
            const color = lightenColor(baseColor, index * 7)
            return (
              <Element key={index} color={color} isFirst={index === 0}>
                {name}
              </Element>
            )
          })}
        </Flex> */}
        <Text fontWeight="bold">Выбранные в режиме multi:</Text>
        <Flex align="center" cursor={'pointer'}>
          {multiSelectedNames.map((name, index) => {
            const color = lightenColor(baseColor, index * 7)
            return (
              <Element key={index} color={color} isFirst={index === 0}>
                {name}
              </Element>
            )
          })}
        </Flex>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Cascader
        value={value}
        onChange={setValue}
        onSelect={(node, selectedPaths) => handleSelect(node, selectedPaths)}
        data={mockGroupedData}
        style={{ width: 'fit-content' }}
        searchable={false}
        renderColumn={(node, column) => renderMenu(node, column)}
        toggleAs={() => <CustomToggle selectedNames={selectedNames} />}
        popupClassName={'custom-dropdown'}
      />
      <hr />
      <br />
    </Box>
  )
}
